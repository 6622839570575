import { useMemo } from 'react';

import {
  endOfDay,
  setHours,
  setMinutes,
  setSeconds,
  startOfDay
} from 'date-fns';
import { useSearchParams } from 'react-router-dom';

export function useDateFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const from = useMemo(() => {
    const newFrom = searchParams.get('from');

    if (newFrom) {
      return newFrom;
    }

    return startOfDay(new Date()).toISOString();
  }, [searchParams]);

  const to = useMemo(() => {
    const newTo = searchParams.get('to');

    if (newTo) {
      return newTo;
    }

    return endOfDay(new Date()).toISOString();
  }, [searchParams]);

  const handleDateChange = (date?: Date) => {
    const params = new URLSearchParams(searchParams);

    if (date) {
      params.set('from', startOfDay(date).toISOString());
      params.set('to', endOfDay(date).toISOString());
    } else {
      params.delete('from');
      params.delete('to');
    }

    setSearchParams(params);
  };

  const handleDateRangeChange = (range: [Date, Date] | undefined) => {
    const params = new URLSearchParams(searchParams);

    if (range?.[0] && range?.[1]) {
      params.set('from', range[0].toISOString());
      params.set('to', range[1].toISOString());
    } else {
      params.delete('from');
      params.delete('to');
    }

    setSearchParams(params);
  };

  const handleTimeRangeChange = (
    range: [Date, Date] | [undefined, undefined],
    isQuickAction?: boolean
  ) => {
    const params = new URLSearchParams(searchParams);
    const currentFrom = params.get('from');
    const currentFromDate = currentFrom
      ? new Date(currentFrom)
      : startOfDay(new Date());
    const currentTo = params.get('to');
    const currentToDate = currentTo
      ? new Date(currentTo)
      : endOfDay(new Date());

    const [from, to] = range;

    if (from && to) {
      if (isQuickAction) {
        params.set('from', from.toISOString());
        params.set('to', to.toISOString());
      } else {
        // prettier-ignore
        const newFrom = setHours(setMinutes(setSeconds(currentFromDate, from.getSeconds()), from.getMinutes()), from.getHours());
        // prettier-ignore
        const newTo = setHours(setMinutes(setSeconds(currentToDate, to.getSeconds()), to.getMinutes()), to.getHours());

        params.set('from', newFrom.toISOString());
        params.set('to', newTo.toISOString());
      }
    } else {
      params.set('from', startOfDay(currentFromDate).toISOString());
      params.set('to', endOfDay(currentToDate).toISOString());
    }

    setSearchParams(params);
  };

  return {
    from,
    to,
    handleDateChange,
    handleDateRangeChange,
    handleTimeRangeChange
  };
}
