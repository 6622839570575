import { usePortalTranslation } from '@hooks/use-portal-translation';

import { isValidPositiveNumber } from '@utils/input-utils';
import { ConvertKilowattToWatt, ConvertWattToKilowatt } from '@utils/utils';

import { AutomaticSaveInput, Typography } from '@destination/components';

import { UpdateSitePeakCapacity } from '@omnis-pulse-types';

export interface IPeakCapacityPower {
  isDisabled: boolean;
  power?: number;
  onSave: (payload: UpdateSitePeakCapacity) => Promise<void>;
}

export const PeakCapacityPower = ({
  isDisabled,
  power,
  onSave
}: IPeakCapacityPower) => {
  const { t, t_generic } = usePortalTranslation('siteSettings');
  const { t: t_errors } = usePortalTranslation('errors');

  const handleSave = async (value: string) => {
    await onSave({ power: ConvertKilowattToWatt(Number(value)) });
  };

  const description = t('peak_capacity.power_description');
  const label = t('peak_capacity.capacity_limit', { titleize: true });
  const invalidHelperText = t_errors('invalid_input', {
    titleize: true
  });
  const errorHelperText = t_errors('failed_to_save', {
    entity: t('peak_capacity.capacity_limit')
  });
  const errorToastMessage = t_errors('failed_to_save_settings', {
    entity: t_generic('literals.peak_capacity')
  });

  return (
    <>
      <Typography variant="smallText">{description}</Typography>
      <div className="flex items-center gap-4">
        <div className="min-w-[200px]">
          <Typography>{label}</Typography>
        </div>
        <div className="w-[200px]">
          <AutomaticSaveInput
            disabled={isDisabled}
            initialValue={
              power != null ? ConvertWattToKilowatt(power).toString() : ''
            }
            id="peak-capacity-value"
            validator={value => isValidPositiveNumber(value)}
            invalidHelperText={invalidHelperText}
            errorHelperText={errorHelperText}
            errorToastMessage={errorToastMessage}
            onSave={handleSave}
            suffixText={<Typography variant="smallTitle">kW</Typography>}
          />
        </div>
      </div>
    </>
  );
};
