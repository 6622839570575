import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';

import { Card, Typography } from '@destination/components';

import { SiteOverviewEnergyChart } from './SiteOverviewEnergyChart';

export const SiteOverview = () => {
  const { id } = useParams();
  const { t } = usePortalTranslation();

  return (
    <div
      className="flex h-full w-full flex-col"
      data-testid="energy-delivered-graph"
    >
      <div className="mb-3 flex flex-col gap-1">
        <Typography variant="sectionTitle">
          {t('labels.entity_overview', {
            entity: t('literals.site', { titleize: true })
          })}
        </Typography>
        <Typography variant="description">
          {t('date_time.last_x_hours_other', {
            titleize: true,
            count: 24
          })}
        </Typography>
      </div>
      <div className="min-h-[304px] w-full flex-1">
        {id && (
          <Card className="h-full">
            <SiteOverviewEnergyChart />
          </Card>
        )}
      </div>
    </div>
  );
};
