import { DetailLine } from '@components/DetailsLine';

import { Typography } from '@destination/components';

interface IChargerPropertyRow {
  'data-testid': string;
  label: string;
  value: string;
}

export const ChargerPropertyRow = ({
  'data-testid': dataTestId,
  label,
  value
}: IChargerPropertyRow) => {
  return (
    <DetailLine data-testid={dataTestId} label={label}>
      <Typography className="break-all font-bold" data-testid="value">
        {value}
      </Typography>
    </DetailLine>
  );
};
