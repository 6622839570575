import { SITE_FALLBACK_BUDGET_PATH } from '@api/paths';
import { modifier } from '@utils/swr-utils';

import { UpdateSiteFallbackBudget } from '@omnis-pulse-types';

export default async function updateSiteLoadManagementFallbackBudget(
  siteId: string,
  payload: UpdateSiteFallbackBudget
) {
  const url = SITE_FALLBACK_BUDGET_PATH(siteId);
  return await modifier(url, 'PATCH', payload);
}
