export const BASE_PATH = '/api';

// CHARGER
export const CHARGER_PATH = `${BASE_PATH}/chargers`;
export const CHARGER_BY_ID_PATH = (id: string) => `${CHARGER_PATH}/${id}`;
export const CHARGER_ACTIONS_PATH = (id: string) =>
  `${CHARGER_BY_ID_PATH(id)}/actions`;
export const CHARGER_EVENTS_PATH = (id: string) =>
  `${CHARGER_BY_ID_PATH(id)}/events`;
export const CHARGER_EVSE_TELEMETRY_PATH = (id: string) =>
  `${CHARGER_BY_ID_PATH(id)}/telemetry/evse-current`;
export const CHARGER_EVSE_AGGREGATED_TELEMETRY_PATH = (id: string) =>
  `${CHARGER_BY_ID_PATH(id)}/telemetry/evse-aggregation`;
export const CHARGER_SESSIONS_PATH = (id: string) =>
  `${CHARGER_BY_ID_PATH(id)}/sessions`;

// GATEWAY
export const GATEWAY_BY_ID_PATH = (id: string) => `${BASE_PATH}/gateways/${id}`;
export const CHARGERS_BY_GATEWAY_BY_ID_PATH = (id: string) =>
  `${BASE_PATH}/gateways/${id}/chargers`;

// SITE
export const SITE_PATH = `${BASE_PATH}/sites`;
export const SITE_BY_ID_PATH = (id: string) => `${SITE_PATH}/${id}`;
export const SITE_EVENTS_PATH = (id: string) => `${SITE_BY_ID_PATH(id)}/events`;

// SITE TELEMETRY
export const SITE_TELEMETRY_CURRENT_PATH = (id: string) =>
  `${SITE_BY_ID_PATH(id)}/telemetry/current`;
export const SITE_TELEMETRY_CURRENT_DELIVERED_PATH = (id: string) =>
  `${SITE_BY_ID_PATH(id)}/telemetry/current-delivered`;
export const SITE_AGGREGATED_TELEMETRY_PATH = (id: string) =>
  `${SITE_BY_ID_PATH(id)}/telemetry/aggregation`;

// SITE ENERGY MANAGEMENT
export const SITE_ENERGY_MANAGEMENT_PATH = (id: string) =>
  `${SITE_BY_ID_PATH(id)}/energy-management`;
export const SITE_PEAK_CAPACITY_PATH = (id: string) =>
  `${SITE_ENERGY_MANAGEMENT_PATH(id)}/peak-capacity`;

// SITE LOAD MANAGEMENT
export const SITE_LOAD_MANAGEMENT_PATH = (id: string) =>
  `${SITE_BY_ID_PATH(id)}/load-management`;
export const SITE_CHARGING_STRATEGY_PATH = (id: string) =>
  `${SITE_LOAD_MANAGEMENT_PATH(id)}/charging-strategy`;
export const SITE_FALLBACK_BUDGET_PATH = (id: string) =>
  `${SITE_LOAD_MANAGEMENT_PATH(id)}/fallback-budget`;
export const SITE_LIMITS_PATH = (id: string) =>
  `${SITE_LOAD_MANAGEMENT_PATH(id)}/limits`;

//SITE LOCAL INTERFACE
export const SITE_LOCAL_MODBUS_INTERFACE_PATH = (id: string) =>
  `${SITE_BY_ID_PATH(id)}/local-interface`;

// SITE DEVICES AND CHARGERS
export const CHARGERS_BY_SITE_ID_PATH = (id: string) =>
  `${SITE_BY_ID_PATH(id)}/chargers`;
export const CHARGER_BY_SITE_ID_PATH = (siteId: string, chargerId: string) =>
  `${CHARGERS_BY_SITE_ID_PATH(siteId)}/${chargerId}`;
export const CHARGER_ACTIVE_SESSIONS_PATH = (
  siteId: string,
  chargerId: string
) => `${CHARGER_BY_SITE_ID_PATH(siteId, chargerId)}/sessions/active-sessions`;
export const CHARGER_FINISHED_SESSIONS_PATH = (
  siteId: string,
  chargerId: string
) => `${CHARGER_BY_SITE_ID_PATH(siteId, chargerId)}/sessions/finished-sessions`;
export const DEVICES_BY_SITE_ID_PATH = (id: string) =>
  `${SITE_BY_ID_PATH(id)}/devices`;
export const GATEWAYS_BY_SITE_ID_PATH = (id: string) =>
  `${SITE_BY_ID_PATH(id)}/gateways`;
export const GRID_METERS_BY_SITE_ID_PATH = (id: string) =>
  `${SITE_BY_ID_PATH(id)}/grid-meters`;

export const PRODUCT_TYPE_DEVICE_MODELS_BY_SITE_ID_PATH = (id: string) =>
  `${DEVICES_BY_SITE_ID_PATH(id)}/device-models`;

// HARDWARE MODEL
export const HARDWARE_MODELS_PATH = `${BASE_PATH}/hardware-models`;

// USER ACCOUNT
export const USER_DETAILS_PATH = `${BASE_PATH}/user-account/me`;
export const USER_NOTIFICATION_SETTINGS_PATH = `${BASE_PATH}/user-account/notification-settings`;
export const USER_NOTIFICATION_SETTINGS_BY_ID_PATH = (id: string) =>
  `${USER_NOTIFICATION_SETTINGS_PATH}/${id}`;
export const USER_ORGANIZATION_DETAILS_PATH = `${BASE_PATH}/user-account/organization`;
export const USER_ORGANIZATIONS_PATH = `${BASE_PATH}/user-account/organizations`;
export const USER_TOKEN_HINT_PATH = (organizationOmnisId: string) =>
  `${BASE_PATH}/user-account/organizations/${organizationOmnisId}/id-token-hint`;
export const USER_ORGANIZATION_USERS_PATH = `${BASE_PATH}/user-account/organization/users`;
export const USER_ORGANIZATION_USERS_BY_ID_PATH = (id: string) =>
  `${USER_ORGANIZATION_USERS_PATH}/${id}`;

//REPORTS
export const REPORTS_PATH = `${BASE_PATH}/reports`;
