import { ReactElement } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { Link, useParams } from 'react-router-dom';

import useSiteGridMeter from '@api/sites/use-site-grid-meter';
import OfflineIcon from '@icons/offline_16.svg';
import OnlineIcon from '@icons/online_16.svg';
import ErrorIcon from '@icons/red_error_16.svg';

import {
  Card,
  DataError,
  LoadingSkeleton,
  StatusIndicator,
  Typography
} from '@destination/components';

import { HealthStatusEnum } from '@omnis-pulse-types';

export const GridMeterCard = () => {
  const { id } = useParams();
  const { gridMeters, isLoading, isError, mutate } = useSiteGridMeter(id);

  const { t } = usePortalTranslation('gridMeters');

  if (isLoading) {
    return (
      <LoadingSkeleton
        isDarker
        className="w-[235px] rounded-[10px]"
        height={115}
      />
    );
  }

  if (isError) {
    return (
      <div className="flex h-[115px] w-[235px] items-center justify-center">
        <DataError onRefresh={() => mutate(undefined, true)} />
      </div>
    );
  }

  if (!gridMeters || gridMeters.length === 0) return null;

  const { status } = gridMeters[0].healthStatus;
  const variant = gridMeters[0].parameters.variant;

  let icon = <ErrorIcon data-testid="error-icon" className="h-4 w-4" />;
  if (status === HealthStatusEnum.HEALTHY)
    icon = <OnlineIcon data-testid="online-icon" className="h-4 w-4" />;
  if (status === HealthStatusEnum.UNKNOWN)
    icon = <OfflineIcon data-testid="offline-icon" className="h-4 w-4" />;

  const variantText = 'types.' + variant.name.toLowerCase();

  return (
    <Link to="devices/grid-meters" data-testid="grid-meter-link">
      <Card className="w-[235px] !px-3 hover:cursor-pointer hover:drop-shadow-hover">
        <Typography variant="smallTitle" className="">
          {gridMeters[0].parameters.type}
        </Typography>
        <GridMeterStatus status={status} Icon={icon} />
        <Typography className="mb-1 mt-[5px] !text-[10px] !leading-[13px]">
          {t('literals.communication_protocol', { titleize: true })}
        </Typography>
        <Typography variant="smallText">
          Modbus {t(variantText, { titleize: true })}
        </Typography>
      </Card>
    </Link>
  );
};

interface IGridMeterStatus {
  status: HealthStatusEnum;
  Icon: ReactElement<SVGElement>;
}

const GridMeterStatus = ({ status, Icon }: IGridMeterStatus) => {
  const { t_generic } = usePortalTranslation();

  let statusText = 'error';
  if (status === HealthStatusEnum.HEALTHY) statusText = 'online';
  if (status === HealthStatusEnum.UNKNOWN) statusText = 'offline';

  return (
    <div className="border-b-[1px] border-[#EBEBEB] pb-1.5 pt-[5px]">
      <StatusIndicator
        icon={Icon}
        label={t_generic('states.' + statusText, { titleize: true })}
        isSmall
      />
    </div>
  );
};
