import { useEffect } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useNavigate } from 'react-router';

import { CreateSiteForm } from '@components/sites/CreateSiteForm';
import { useReadOnly } from '@context/ReadOnlyContext';

import { Typography } from '@destination/components';

export const Create = () => {
  const { t } = usePortalTranslation();

  const navigate = useNavigate();
  const { isReadOnly } = useReadOnly();

  useEffect(() => {
    if (isReadOnly) {
      navigate('/sites', { replace: true });
    }
  }, [isReadOnly, navigate]);

  if (isReadOnly) {
    return null;
  }

  return (
    <>
      <Typography variant="pageTitle" className="mb-6">
        {t('buttons.create', {
          titleize: true,
          entity: t('literals.site')
        })}
      </Typography>
      <CreateSiteForm />
    </>
  );
};
