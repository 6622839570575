import { useEffect, useMemo, useState } from 'react';

import {
  TOverloadedFunction,
  usePortalTranslation
} from '@hooks/use-portal-translation';
import { getTime } from 'date-fns';
import { Area, CartesianGrid, Line, Tooltip, XAxis, YAxis } from 'recharts';

import { ComposedGraph } from '@components/charts/ComposedGraph';
import { CustomCursor } from '@components/charts/CustomCursor';
import { CustomYAxisTick } from '@components/charts/CustomYAxisTick';
import { useInsightsContext } from '@context/InsightsContext';
import { formatDate, timeFormat } from '@utils/date-utils';
import { getTicksForInterval } from '@utils/insights-utils';

import { LoadingSkeleton } from '@destination/components';

import {
  ActiveLoadManagementMode,
  SiteTelemetryPoint
} from '@omnis-pulse-types';

import { CurrentOrEnergyDeliveredLegend } from '../CurrentOrEnergyDeliveredLegend';
import { COLORS } from '../site-insights-colors';
import { PHASE_1, PHASE_2, PHASE_3 } from '../site-insights-tooltip-utils';
import { CurrentDeliveredGraphTooltip } from './CurrentDeliveredGraphTooltip';

export type AggregatedData = (Omit<SiteTelemetryPoint, 'timestamp'> & {
  timestamp: number;
})[];

interface IAggregatedData {
  data: AggregatedData;
  isLoading: boolean;
  activePhase?: string;
}

type DataKey =
  | 'residual_current_l1'
  | 'residual_current_l2'
  | 'residual_current_l3'
  | 'chargers_current_l1'
  | 'chargers_current_l2'
  | 'chargers_current_l3'
  | 'grid_limit_current'
  | 'load_shedding_current'
  | 'charger_budget_current_l1'
  | 'charger_budget_current_l2'
  | 'charger_budget_current_l3'
  | 'peak_capacity_limit'
  | 'current_l1_range'
  | 'charger_l1_range'
  | 'current_l2_range'
  | 'charger_l2_range'
  | 'current_l3_range'
  | 'charger_l3_range';

export type VisibilityState = Record<DataKey, boolean>;

interface RangeValue extends Array<number> {
  0: number;
  1: number;
  length: 2;
}

export const CurrentDeliveredGraph = ({
  data,
  isLoading,
  activePhase = PHASE_1
}: IAggregatedData) => {
  const { t } = usePortalTranslation();
  const {
    range,
    handleZoom,
    handleResetZoom,
    activeLoadManagementMode,
    isPeakCapacityEnabled,
    peakCapacityLimit,
    isLocalModbusEnabled
  } = useInsightsContext();

  const isStaticMode =
    activeLoadManagementMode === ActiveLoadManagementMode.STATIC;

  const [visibleLines, setVisibleLines] = useState<VisibilityState>({
    chargers_current_l1: true,
    residual_current_l1: true,
    chargers_current_l2: false,
    residual_current_l2: false,
    chargers_current_l3: false,
    residual_current_l3: false,
    grid_limit_current: true,
    load_shedding_current: true,
    charger_budget_current_l1: isStaticMode,
    charger_budget_current_l2: false,
    charger_budget_current_l3: false,
    peak_capacity_limit: isPeakCapacityEnabled,
    current_l1_range: true,
    charger_l1_range: true,
    current_l2_range: false,
    charger_l2_range: false,
    current_l3_range: false,
    charger_l3_range: false
  });

  const axis = useMemo(() => {
    return getAxis([getTime(range[0]), getTime(range[1])]);
  }, [range]);

  // Add peak capacity limit to each data point if enabled
  const enhancedData = useMemo(() => {
    if (isPeakCapacityEnabled && peakCapacityLimit !== null) {
      return data.map(point => ({
        ...point,
        peak_capacity_limit: peakCapacityLimit
      }));
    }
    return data;
  }, [data, isPeakCapacityEnabled, peakCapacityLimit]);

  const rangeData = useMemo(() => {
    return enhancedData.map(point => {
      return {
        ...point,
        current_l1_range: [0, point.residual_current_l1 ?? 0] as RangeValue,
        charger_l1_range: [
          point.residual_current_l1 ?? 0, // Start at residual value
          (point.residual_current_l1 ?? 0) + (point.chargers_current_l1 ?? 0)
        ] as RangeValue,

        current_l2_range: [0, point.residual_current_l2 ?? 0] as RangeValue,
        charger_l2_range: [
          point.residual_current_l2 ?? 0,
          (point.residual_current_l2 ?? 0) + (point.chargers_current_l2 ?? 0)
        ] as RangeValue,

        current_l3_range: [0, point.residual_current_l3 ?? 0] as RangeValue,
        charger_l3_range: [
          point.residual_current_l3 ?? 0,
          (point.residual_current_l3 ?? 0) + (point.chargers_current_l3 ?? 0)
        ] as RangeValue
      };
    });
  }, [enhancedData]);

  useEffect(() => {
    const newVisibleLines: VisibilityState = {
      chargers_current_l1: false,
      residual_current_l1: false,
      chargers_current_l2: false,
      residual_current_l2: false,
      chargers_current_l3: false,
      residual_current_l3: false,
      grid_limit_current: true,
      load_shedding_current: isLocalModbusEnabled,
      charger_budget_current_l1: false,
      charger_budget_current_l2: false,
      charger_budget_current_l3: false,
      peak_capacity_limit: isPeakCapacityEnabled,
      current_l1_range: false,
      charger_l1_range: false,
      current_l2_range: false,
      charger_l2_range: false,
      current_l3_range: false,
      charger_l3_range: false
    };

    if (activePhase === PHASE_1) {
      newVisibleLines.current_l1_range = true;
      newVisibleLines.charger_l1_range = true;
      newVisibleLines.charger_budget_current_l1 = isStaticMode;
    } else if (activePhase === PHASE_2) {
      newVisibleLines.current_l2_range = true;
      newVisibleLines.charger_l2_range = true;
      newVisibleLines.charger_budget_current_l2 = isStaticMode;
    } else if (activePhase === PHASE_3) {
      newVisibleLines.current_l3_range = true;
      newVisibleLines.charger_l3_range = true;
      newVisibleLines.charger_budget_current_l3 = isStaticMode;
    }

    setVisibleLines(newVisibleLines);
  }, [activePhase, isStaticMode, isPeakCapacityEnabled, isLocalModbusEnabled]);

  return (
    <div className="flex flex-col">
      <div
        className="relative h-[343px] flex-auto"
        data-testid="current-delivered-graph"
      >
        {isLoading && (
          <div className="absolute inset-0 z-50 mb-[33px] ml-[94px] mr-1 mt-1 bg-white">
            <LoadingSkeleton className="!h-full rounded-[10px]" height={0} />
          </div>
        )}
        <ComposedGraph
          onZoom={handleZoom}
          onResetZoom={handleResetZoom}
          hideCartesianGrid
        >
          <CartesianGrid strokeDasharray="5 5" stroke="#EBEBEB" />
          {axis}
          <Tooltip
            allowEscapeViewBox={{ x: true, y: true }}
            offset={0}
            isAnimationActive={false}
            shared={true}
            content={
              <CurrentDeliveredGraphTooltip
                isStaticMode={isStaticMode}
                t={t}
                activePhase={activePhase}
                chartData={rangeData}
              />
            }
            cursor={data.length ? <CustomCursor /> : <></>}
            wrapperStyle={{ zIndex: 100, pointerEvents: 'none' }}
          />
          {stackedAreas(rangeData, t).filter(area => {
            const key = area.key;
            if (key === 'residual_l1') return visibleLines.current_l1_range;
            if (key === 'chargers_l1') return visibleLines.charger_l1_range;
            if (key === 'residual_l2') return visibleLines.current_l2_range;
            if (key === 'chargers_l2') return visibleLines.charger_l2_range;
            if (key === 'residual_l3') return visibleLines.current_l3_range;
            if (key === 'chargers_l3') return visibleLines.charger_l3_range;
            return false;
          })}
          {lines(enhancedData, t, isPeakCapacityEnabled).filter(
            line => visibleLines[line.props.dataKey as DataKey]
          )}
        </ComposedGraph>
      </div>
      <div className="flex-initial">
        <CurrentOrEnergyDeliveredLegend />
      </div>
    </div>
  );
};

const getAxis = (domain: [number, number]) => [
  <XAxis
    key={1}
    dataKey="timestamp"
    type="number"
    scale="time"
    domain={domain}
    stroke={'#EBEBEB'}
    tickFormatter={tick => formatDate(tick, timeFormat)}
    tick={{ fontSize: 12, fill: '#9F9F9F', fontFamily: 'ABBvoice' }}
    ticks={getTicksForInterval(domain)}
    tickLine={false}
  />,
  <YAxis
    key={2}
    stroke={'#EBEBEB'}
    padding={{ top: 16 }}
    tickFormatter={value => `${value} A`}
    tick={<CustomYAxisTick />}
    tickLine={false}
    width={92}
  />
];

const stackedAreas = (data: AggregatedData, t: TOverloadedFunction) => {
  const areaComponents = [];

  areaComponents.push(
    <Area
      data={data}
      key="residual_l1"
      dataKey="current_l1_range"
      name={`${t('labels.entity_load', { entity: t('literals.residual'), titleize: true })}`}
      fill="#36F"
      stroke="#36F"
      fillOpacity={0.6}
      unit="A"
      type="monotone"
      isAnimationActive={false}
      connectNulls={true}
    />
  );

  areaComponents.push(
    <Area
      data={data}
      key="chargers_l1"
      dataKey="charger_l1_range"
      name={`${t('labels.entity_consumption', { titleize: true, entity: t('literals.charger_other') })}`}
      fill="#3DA190"
      stroke="#3DA190"
      fillOpacity={0.6}
      unit="A"
      type="monotone"
      isAnimationActive={false}
      connectNulls={true}
    />
  );

  areaComponents.push(
    <Area
      data={data}
      key="residual_l2"
      dataKey="current_l2_range"
      name={`${t('labels.entity_load', { entity: t('literals.residual'), titleize: true })}`}
      fill="#36F"
      stroke="#36F"
      fillOpacity={0.6}
      unit="A"
      type="monotone"
      isAnimationActive={false}
      connectNulls={true}
    />
  );

  areaComponents.push(
    <Area
      data={data}
      key="chargers_l2"
      dataKey="charger_l2_range"
      name={`${t('labels.entity_consumption', { titleize: true, entity: t('literals.charger_other') })} - p2`}
      fill="#3DA190"
      stroke="#3DA190"
      fillOpacity={0.6}
      unit="A"
      type="monotone"
      isAnimationActive={false}
      connectNulls={true}
    />
  );

  areaComponents.push(
    <Area
      data={data}
      key="residual_l3"
      dataKey="current_l3_range"
      name={`${t('labels.entity_load', { entity: t('literals.residual'), titleize: true })}`}
      fill={COLORS.RESIDUAL}
      stroke={COLORS.RESIDUAL}
      fillOpacity={0.6}
      unit="A"
      type="monotone"
      isAnimationActive={false}
      connectNulls={true}
    />
  );

  areaComponents.push(
    <Area
      data={data}
      key="chargers_l3"
      dataKey="charger_l3_range"
      name={`${t('labels.entity_consumption', { titleize: true, entity: t('literals.charger_other') })}`}
      fill={COLORS.CHARGERS}
      stroke={COLORS.CHARGERS}
      fillOpacity={0.6}
      unit="A"
      type="monotone"
      isAnimationActive={false}
      connectNulls={true}
    />
  );

  return areaComponents;
};

interface LineProps {
  dataKey: string;
  name: string;
  stroke: string;
  strokeWidth: number;
  dot: boolean;
  strokeDasharray?: string;
}

const lines = (
  data: AggregatedData,
  t: TOverloadedFunction,
  isPeakCapacityEnabled: boolean
) => {
  const lineConfigs: LineProps[] = [
    {
      dataKey: 'grid_limit_current',
      name: `${t('literals.grid_limit', { titleize: true })}`,
      stroke: COLORS.GRID_LIMIT,
      strokeWidth: 2,
      dot: false
    },
    {
      dataKey: 'load_shedding_current',
      name: `${t('labels.entity_limit', { entity: t('literals.modbus'), titleize: true })}`,
      stroke: COLORS.LOAD_SHEDDING,
      strokeWidth: 2,
      dot: false
    },
    {
      dataKey: 'charger_budget_current_l1',
      name: `${t('labels.entity_limit', { entity: t('literals.charging_budget'), titleize: true })}`,
      stroke: COLORS.CHARGING_BUDGET,
      strokeWidth: 2,
      dot: false
    },
    {
      dataKey: 'charger_budget_current_l2',
      name: `${t('labels.entity_limit', { entity: t('literals.charging_budget'), titleize: true })}`,
      stroke: COLORS.CHARGING_BUDGET,
      strokeWidth: 2,
      dot: false
    },
    {
      dataKey: 'charger_budget_current_l3',
      name: `${t('labels.entity_limit', { entity: t('literals.charging_budget'), titleize: true })}`,
      stroke: COLORS.CHARGING_BUDGET,
      strokeWidth: 2,
      dot: false
    }
  ];

  // Add peak capacity limit line if enabled
  if (isPeakCapacityEnabled) {
    lineConfigs.push({
      dataKey: 'peak_capacity_limit',
      name: `${t('labels.entity_limit', { entity: t('literals.peak_capacity'), titleize: true })}`,
      stroke: COLORS.PEAK_CAPACITY,
      strokeWidth: 2,
      strokeDasharray: '4 4',
      dot: false
    });
  }

  return lineConfigs.map((line: LineProps, index: number) => (
    <Line
      data={data}
      key={`line-${index + 1}`}
      {...line}
      unit="A"
      type="monotone"
      isAnimationActive={false}
      connectNulls={true}
    />
  ));
};
