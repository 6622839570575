import { createContext, ReactNode, useContext, useMemo } from 'react';

import useUserDetails from '@api/user-account/use-user-details';
import useUserOrganizations from '@api/user-account/use-user-organizations';

import { ApplicationRole } from '@omnis-pulse-types';

interface IReadOnlyContext {
  isReadOnly: boolean;
}

const ReadOnlyContext = createContext<IReadOnlyContext | undefined>(undefined);

export const ReadOnlyProvider = ({ children }: { children: ReactNode }) => {
  const { organizations } = useUserOrganizations();
  const { userDetails } = useUserDetails();

  const isReadOnly = useMemo(() => {
    const activeOrganization = organizations?.find(
      organization =>
        organization.organizationOmnisId === userDetails?.organization.omnisId
    );

    if (activeOrganization?.roles?.length === 1) {
      return activeOrganization.roles[0] === ApplicationRole.READ_ONLY;
    }

    return false;
  }, [organizations, userDetails]);

  const contextValue = useMemo(() => ({ isReadOnly }), [isReadOnly]);

  return (
    <ReadOnlyContext.Provider value={contextValue}>
      {children}
    </ReadOnlyContext.Provider>
  );
};

export const useReadOnly = () => {
  const context = useContext(ReadOnlyContext);
  if (!context) {
    throw new Error('useReadOnly must be used within a ReadOnlyProvider');
  }
  return context;
};
