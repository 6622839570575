import { usePortalTranslation } from '@hooks/use-portal-translation';

import { isValidPositiveNumber } from '@utils/input-utils';
import { ConvertKilowattToWatt, ConvertWattToKilowatt } from '@utils/utils';

import { AutomaticSaveInput, Typography } from '@destination/components';

import { UpdateSiteChargingStrategy } from '@omnis-pulse-types';

export interface IChargingStrategyBasePower {
  isDisabled: boolean;
  basePower?: number;
  onSave: (payload: UpdateSiteChargingStrategy) => Promise<void>;
}

export const ChargingStrategyBasePower = ({
  isDisabled,
  basePower,
  onSave
}: IChargingStrategyBasePower) => {
  const { t, t_generic } = usePortalTranslation('errors');

  const label = t_generic('literals.base_power', { titleize: true });
  const invalidHelperText = t('invalid_input', {
    titleize: true
  });
  const errorHelperText = t('failed_to_save', {
    entity: t_generic('literals.base_power')
  });
  const errorToastMessage = t('failed_to_save_settings', {
    entity: t_generic('literals.base_power')
  });

  const handleSave = async (value: string) => {
    await onSave({ basePower: ConvertKilowattToWatt(Number(value)) });
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-4">
        <div className="min-w-[200px]">
          <Typography>{label}</Typography>
        </div>
        <div className="w-[200px]">
          <AutomaticSaveInput
            data-testid="base-power-input"
            disabled={isDisabled}
            initialValue={
              basePower != null
                ? ConvertWattToKilowatt(basePower).toString()
                : ''
            }
            id="base-power-value"
            validator={value => isValidPositiveNumber(value)}
            invalidHelperText={invalidHelperText}
            errorHelperText={errorHelperText}
            errorToastMessage={errorToastMessage}
            onSave={handleSave}
            suffixText={<Typography variant="smallTitle">kW</Typography>}
          />
        </div>
      </div>
    </div>
  );
};
