import { CHARGER_BY_ID_PATH } from '@api/paths';
import { modifier } from '@utils/swr-utils';

import { UpdateChargerSafetyLimitCommand } from '@omnis-pulse-types';

export default function updateChargerSafetyLimit(
  chargerId: string,
  payload: UpdateChargerSafetyLimitCommand
) {
  const url = `${CHARGER_BY_ID_PATH(chargerId)}/safety-limit`;
  return modifier(url, 'PATCH', payload);
}
