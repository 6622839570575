import { useMemo } from 'react';

import {
  TOverloadedFunction,
  usePortalTranslation
} from '@hooks/use-portal-translation';
import { getTime } from 'date-fns';
import {
  CartesianGrid,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { ComposedGraph } from '@components/charts/ComposedGraph';
import { CustomCursor } from '@components/charts/CustomCursor';
import { CustomYAxisTick } from '@components/charts/CustomYAxisTick';
import { UNIT_AMPERE, useInsightsContext } from '@context/InsightsContext';
import { formatDate, timeFormat } from '@utils/date-utils';
import { getTicksForInterval } from '@utils/insights-utils';

import { LoadingSkeleton } from '@destination/components';

import { ChargingTelemetryPoint } from '@omnis-pulse-types';

import { VisibilityState } from '../ChargerCurrentDelivered';
import { EvsePhaseChartTooltip } from './EvsePhaseChartTooltip';

type ChartDataPoint = Omit<ChargingTelemetryPoint, 'timestamp'> & {
  timestamp: string | number;
  numericTimestamp?: number;
  soc?: number | null;
  charging_budget_limit_current?: number | null;
  charging_budget_limit_power?: number | null;
  chargers_power?: number | null;
};

interface IEvsePhaseChart {
  isLoading?: boolean;
  data: ChartDataPoint[];
  visibleLines?: VisibilityState;
  height?: string;
  width?: string;
  startTime?: string;
  endTime?: string;
}

export const EvsePhaseChart = ({
  isLoading = false,
  data,
  height = '100%',
  width = '100%',
  startTime,
  endTime,
  visibleLines
}: IEvsePhaseChart) => {
  const { t, t_generic } = usePortalTranslation('chargers');
  const { range, handleResetZoom, handleZoom, activeUnit } = useInsightsContext(
    startTime ? new Date(startTime) : undefined,
    endTime ? new Date(endTime) : undefined
  );

  const testId =
    activeUnit === UNIT_AMPERE
      ? 'evse-current-delivered-graph'
      : 'evse-power-delivered-graph';

  const chartData = useMemo(() => {
    return data.map(point => {
      const chartPoint: ChartDataPoint = { ...point };

      if (typeof chartPoint.timestamp === 'string') {
        chartPoint.timestamp = getTime(new Date(chartPoint.timestamp));
      } else if (chartPoint.numericTimestamp !== undefined) {
        chartPoint.timestamp = chartPoint.numericTimestamp;
      } else if (typeof chartPoint.timestamp !== 'number') {
        chartPoint.timestamp = getTime(new Date());
      }

      if (activeUnit !== UNIT_AMPERE) {
        // Calculate the total power by summing up the individual phase powers
        const power_l1 =
          typeof chartPoint.chargers_power_l1 === 'number'
            ? chartPoint.chargers_power_l1
            : 0;
        const power_l2 =
          typeof chartPoint.chargers_power_l2 === 'number'
            ? chartPoint.chargers_power_l2
            : 0;
        const power_l3 =
          typeof chartPoint.chargers_power_l3 === 'number'
            ? chartPoint.chargers_power_l3
            : 0;

        // Set the total power value
        chartPoint.chargers_power = (power_l1 + power_l2 + power_l3) / 1000;

        // Also convert individual phase powers to kW for tooltip display
        if (typeof chartPoint.chargers_power_l1 === 'number') {
          chartPoint.chargers_power_l1 = chartPoint.chargers_power_l1 / 1000;
        }

        if (typeof chartPoint.chargers_power_l2 === 'number') {
          chartPoint.chargers_power_l2 = chartPoint.chargers_power_l2 / 1000;
        }

        if (typeof chartPoint.chargers_power_l3 === 'number') {
          chartPoint.chargers_power_l3 = chartPoint.chargers_power_l3 / 1000;
        }

        if (typeof chartPoint.charging_budget_limit_power === 'number') {
          chartPoint.charging_budget_limit_power =
            chartPoint.charging_budget_limit_power / 1000;
        }
      }

      return chartPoint;
    });
  }, [data, activeUnit]);

  return (
    <div className="relative flex-auto" data-testid={testId}>
      {isLoading && (
        <div className="absolute inset-0 z-50 mb-[33px] ml-[46px] mr-[52px] mt-1 bg-white">
          <LoadingSkeleton className="!h-full rounded-[10px]" height={0} />
        </div>
      )}
      <ResponsiveContainer
        width={width}
        height={height}
        className="[&>div>div>svg]:overflow-visible"
      >
        <ComposedGraph onZoom={handleZoom} onResetZoom={handleResetZoom}>
          <CartesianGrid strokeDasharray="5 5" stroke="#EBEBEB" />
          <XAxis
            dataKey="timestamp"
            type="number"
            scale="time"
            domain={[getTime(range[0]), getTime(range[1])]}
            stroke={'#EBEBEB'}
            tickFormatter={tick => {
              return formatDate(new Date(tick).toISOString(), timeFormat);
            }}
            tick={{ fontSize: 12, fill: '#9F9F9F', fontFamily: 'ABBvoice' }}
            ticks={getTicksForInterval([getTime(range[0]), getTime(range[1])])}
            tickLine={false}
          />

          <YAxis
            yAxisId="left"
            stroke={'#EBEBEB'}
            padding={{ top: 16 }}
            tickFormatter={value => value}
            tick={<CustomYAxisTick />}
            tickLine={false}
            width={44}
            orientation="left"
            label={{
              value: `${activeUnit === UNIT_AMPERE ? 'A' : 'kW'}`,
              position: 'top',
              offset: 0,
              style: {
                fontSize: 12,
                textAnchor: 'middle',
                fill: '#9F9F9F',
                fontFamily: 'ABBvoice'
              }
            }}
          />

          <YAxis
            yAxisId="right"
            orientation="right"
            domain={[0, 100]}
            stroke={'#EBEBEB'}
            padding={{ top: 16 }}
            tickFormatter={value => `${value}%`}
            tick={{ fontSize: 12, fill: '#9F9F9F', fontFamily: 'ABBvoice' }}
            tickLine={false}
            width={44}
            label={{
              value: 'SoC',
              position: 'top',
              offset: 0,
              style: {
                fontSize: 12,
                textAnchor: 'middle',
                fill: '#9F9F9F',
                fontFamily: 'ABBvoice'
              }
            }}
          />

          <Tooltip
            allowEscapeViewBox={{ x: true, y: true }}
            offset={0}
            isAnimationActive={false}
            content={<EvsePhaseChartTooltip />}
            cursor={chartData.length ? <CustomCursor /> : <></>}
          />

          {renderLines(chartData, t_generic, t, activeUnit, visibleLines)}

          <Line
            yAxisId="right"
            data={chartData}
            dataKey="soc"
            name={t('literals.state_of_charge')}
            stroke="#E64997"
            unit="%"
            type="monotone"
            isAnimationActive={false}
            dot={false}
            activeDot={false}
            connectNulls={true}
          />

          <Line
            yAxisId="left"
            data={chartData}
            dataKey={
              activeUnit === UNIT_AMPERE
                ? 'charging_budget_limit_current'
                : 'charging_budget_limit_power'
            }
            name={
              activeUnit === UNIT_AMPERE
                ? 'charging_budget_limit_current'
                : 'charging_budget_limit_power'
            }
            stroke="#00ACEC"
            unit={activeUnit === UNIT_AMPERE ? 'A' : 'kW'}
            type="stepAfter"
            isAnimationActive={false}
            dot={false}
            activeDot={false}
            connectNulls={true}
          />
        </ComposedGraph>
      </ResponsiveContainer>
    </div>
  );
};

const renderLines = (
  data: ChartDataPoint[],
  t: TOverloadedFunction,
  t_chargers: TOverloadedFunction,
  activeUnit: string,
  visibleLines?: VisibilityState
) => {
  const isAmpere = activeUnit === UNIT_AMPERE;
  const metricType = isAmpere
    ? 'literals.charging_current'
    : 'literals.charging_power';

  const lineConfigs = isAmpere
    ? [
        {
          dataKey: 'chargers_current_l1',
          name: `${t_chargers(metricType, { titleize: true })} - ${t('literals.phase 1')}`,
          stroke: '#000000',
          visible: visibleLines?.l1 !== false
        },
        {
          dataKey: 'chargers_current_l2',
          name: `${t_chargers(metricType, { titleize: true })} - ${t('literals.phase 2')}`,
          stroke: '#77A3FC',
          visible: visibleLines?.l2 !== false
        },
        {
          dataKey: 'chargers_current_l3',
          name: `${t_chargers(metricType, { titleize: true })} - ${t('literals.phase 3')}`,
          stroke: '#FF9F8C',
          visible: visibleLines?.l3 !== false
        }
      ]
    : [
        {
          dataKey: 'chargers_power',
          name: `${t_chargers(metricType, { titleize: true })}`,
          stroke: '#000000',
          visible: visibleLines?.power !== false
        }
      ];

  return lineConfigs
    .filter(config => config.visible !== false)
    .map((line, index) => (
      <Line
        yAxisId="left"
        data={data}
        key={index + 1}
        {...line}
        unit={isAmpere ? 'A' : 'kW'}
        type="monotone"
        isAnimationActive={false}
        dot={false}
        activeDot={false}
        connectNulls={true}
      />
    ));
};
