import { Key } from 'react';

import { formatDate, timeFormat } from '@utils/date-utils';

import { TooltipItem } from './CustomTooltipItem';

interface AdditionalField {
  key: string;
  label: string;
  stroke?: string;
  unit?: string | ((activeUnit?: string) => string);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomFloatingTooltip = (props: any) => {
  if (!props.active || !props.payload?.length) {
    return null;
  }

  const firstPayload = props.payload[0]?.payload || {};

  const width = props.width || '200px';
  const additionalFields = props.additionalFields || [];
  const filterPayloadFn = props.filterPayload;

  const payloadToRender = filterPayloadFn
    ? props.payload.filter(filterPayloadFn)
    : props.payload;

  return (
    <div
      className={`drop-shadow-[0_0_15px_rgba(0,0,0,0.2)} ml-2 mt-3 rounded-[4px] bg-white p-2`}
      style={{ width }}
    >
      <div className="flex flex-col gap-2">
        <TooltipItem
          label={props.timestampLabel || 'Time stamp'}
          value={
            props.formatTimestamp
              ? props.formatTimestamp(props.label)
              : formatDate(props.label, timeFormat)
          }
        />

        {payloadToRender.length > 0 &&
          payloadToRender.map(
            (item: {
              dataKey: Key | null | undefined;
              name: string;
              value: string | number;
              stroke: string | undefined;
              unit: string | undefined;
            }) => (
              <TooltipItem
                key={item.dataKey}
                label={item.name}
                value={item.value}
                stroke={item.stroke}
                unit={item.unit ?? ''}
              />
            )
          )}

        {additionalFields.map((field: AdditionalField) => {
          const value = firstPayload[field.key];
          if (value !== undefined) {
            const unit =
              typeof field.unit === 'function'
                ? field.unit(props.activeUnit)
                : field.unit;

            return (
              <TooltipItem
                key={field.key}
                label={field.label}
                value={value}
                stroke={field.stroke}
                unit={unit}
              />
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};
