import { ReactNode } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DottedLegendItem } from '@components/charts/DottedLegendItem';
import { LegendItem } from '@components/charts/LegendItem';
import { useInsightsContext } from '@context/InsightsContext';

import { ActiveLoadManagementMode } from '@omnis-pulse-types';

export const CurrentOrEnergyDeliveredLegend = (): ReactNode => {
  const { t } = usePortalTranslation();
  const {
    activeLoadManagementMode,
    isPeakCapacityEnabled,
    isLocalModbusEnabled
  } = useInsightsContext();

  const isStaticMode =
    activeLoadManagementMode === ActiveLoadManagementMode.STATIC;

  const labels = {
    consumption: t('labels.entity_consumption', {
      entity: t('literals.charger_other'),
      titleize: true
    }),
    residual: t('labels.entity_load', {
      entity: t('literals.residual'),
      titleize: true
    }),
    gridLimit: t('literals.grid_limit', { titleize: true }),
    chargerBudget: t('labels.entity_limit', {
      entity: t('literals.charging_budget'),
      titleize: true
    }),
    modbusLimit: `${t('labels.entity_limit', { entity: t('literals.modbus'), titleize: true })}`,
    peakCapacityLimit: `${t('labels.entity_limit', { entity: t('literals.peak_capacity'), titleize: true })}`
  };

  return (
    <div className="ml-[94px]">
      <div className="mb-3 flex items-center gap-6">
        <LegendItem color="#00ACEC" label={labels.gridLimit} type="line" />
        {/* Only show modbus limit legend item if local modbus is enabled */}
        {isLocalModbusEnabled && (
          <LegendItem color="#F2AC66" label={labels.modbusLimit} type="line" />
        )}
        {/* Only show charger budget legend item in STATIC mode */}
        {isStaticMode && (
          <LegendItem
            color="#CC50CC"
            label={labels.chargerBudget}
            type="line"
          />
        )}
        {/* Show peak capacity limit legend item if enabled */}
        {isPeakCapacityEnabled && (
          <DottedLegendItem color="#848484" label={labels.peakCapacityLimit} />
        )}
      </div>
      <div className="flex items-center gap-6">
        <LegendItem color="#3DA190" label={labels.consumption} />
        <LegendItem color="#36F" label={labels.residual} />
      </div>
    </div>
  );
};
