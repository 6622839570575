import { useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import { DetailLine } from '@components/DetailsLine';
import { useReadOnly } from '@context/ReadOnlyContext';
import EditIcon from '@icons/edit_16.svg';

import { IconButton, Typography } from '@destination/components';

import { EditChargerNameDialog } from './edit-charger/EditChargerNameDialog';

interface IChargerNamePropertyRow {
  name: string;
}

export const ChargerNamePropertyRow = ({ name }: IChargerNamePropertyRow) => {
  const [showDialog, setShowDialog] = useState(false);
  const { isReadOnly } = useReadOnly();

  const { t } = usePortalTranslation();

  const label = t('literals.name', { titleize: true });

  return (
    <>
      <DetailLine label={label} data-testid="charger-name">
        <div className="flex items-center gap-1">
          <Typography className="break-all font-bold" data-testid="value">
            {name}
          </Typography>
          <div className="-mt-[14px] max-h-[18px]">
            <IconButton
              data-testid="edit-icon-button"
              disabled={isReadOnly}
              onClick={() => setShowDialog(true)}
            >
              <EditIcon />
            </IconButton>
          </div>
        </div>
      </DetailLine>
      <EditChargerNameDialog
        showDialog={showDialog}
        name={name}
        onClose={() => setShowDialog(false)}
      />
    </>
  );
};
