import { useEffect, useMemo } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useNavigate, useParams } from 'react-router-dom';
import { mutate } from 'swr';

import useGateway from '@api/gateways/use-gateway';
import {
  DEVICES_BY_SITE_ID_PATH,
  GRID_METERS_BY_SITE_ID_PATH
} from '@api/paths';
import createSiteDevice from '@api/sites/create-site-device';
import useProductTypeDeviceModels from '@api/sites/devices/use-product-type-device-models';
import { GridMeterActionButtons } from '@components/grid-meters/add-grid-meter/GridMeterActionButtons';
import { GridMeterManufacturerAndTypeCard } from '@components/grid-meters/add-grid-meter/GridMeterManufacturerAndTypeCard';
import { GridMeterParameters } from '@components/grid-meters/add-grid-meter/GridMeterParameters';
import { GridMeterProtocolCard } from '@components/grid-meters/add-grid-meter/GridMeterProtocolCard';
import {
  filterConfigurableParameters,
  useGridMeterContext
} from '@context/GridMeterContext';
import { useReadOnly } from '@context/ReadOnlyContext';
import { useSiteDevicesContext } from '@context/SiteDevicesContext';

import { LoadingSkeleton, Typography, useAlert } from '@destination/components';

import { ConnectionStatusEnum } from '@omnis-pulse-types';

import { DelayedNavigationDisclaimer } from './DelayedNavigationWarning';

export const Add = () => {
  const {
    productTypeIdentifier,
    selectedProtocol,
    gridMeterParameters,
    updateExtraParameters,
    updateGridMeterParameters,
    isValidForm,
    isProcessing,
    setIsProcessing
  } = useGridMeterContext();

  const { isReadOnly } = useReadOnly();

  const { id = '' } = useParams();
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useAlert();
  const { t, t_generic } = usePortalTranslation('gridMeters');

  const { deviceModels, isLoading } = useProductTypeDeviceModels(
    id,
    productTypeIdentifier!
  );

  const title = t_generic('buttons.add', {
    entity: t_generic('literals.grid_meter'),
    titleize: true
  });

  const protocols = useMemo(
    () =>
      deviceModels?.variants
        .map(variant => variant.name)
        .filter(variant => variant === 'tcp') ?? [],
    [deviceModels]
  );

  const parameters = useMemo(
    () =>
      filterConfigurableParameters(
        deviceModels?.variants.find(
          variant => variant.name === selectedProtocol
        )?.parameters || []
      ),
    [deviceModels, selectedProtocol]
  );

  const isUnsupportedProductType = useMemo(
    () =>
      productTypeIdentifier !== null &&
      (deviceModels?.variants.length === 0 ||
        !deviceModels?.variants.find(variant => variant.name === 'tcp')),
    [deviceModels, productTypeIdentifier]
  );

  useEffect(() => {
    if (isReadOnly) {
      navigate(`/sites/${id}/devices/grid-meters`, { replace: true });
    }
  }, [id, isReadOnly, navigate]);

  useEffect(() => {
    updateGridMeterParameters(
      parameters?.map(param => ({
        name: param.name,
        value: param.defaultValue
      })) ?? []
    );
  }, [parameters, updateGridMeterParameters]);

  useEffect(() => {
    updateExtraParameters(
      deviceModels?.extraParameters?.map(param => ({
        name: param.name,
        value: param.defaultValue
      })) ?? []
    );
  }, [deviceModels?.extraParameters, updateExtraParameters]);

  const handleAddGridMeter = async () => {
    setIsProcessing(true);
    const { error } = await createSiteDevice(id, gridMeterParameters);

    if (error) {
      notifyError({ header: t('notifications.error_adding_new_grid_meter') });
      setIsProcessing(false);
      return;
    }

    notifySuccess({ header: t('notifications.new_grid_meter_added') });
    navigate('..', { relative: 'path' });
    await mutate(DEVICES_BY_SITE_ID_PATH);
    await mutate(GRID_METERS_BY_SITE_ID_PATH);
    setIsProcessing(false);
  };

  const { deviceData } = useSiteDevicesContext();

  const { gateway, isLoading: isLoadingGateway } = useGateway(
    deviceData.gateways[0].id
  );

  const isGatewayOnline =
    gateway?.connectionStatus.find(status => status.type === 'ABB')?.status ===
    ConnectionStatusEnum.CONNECTED;

  if (isReadOnly) {
    return null;
  }

  if (isLoadingGateway) {
    return <LoadingSkeleton height={200} />;
  }

  if (deviceData.gridMeters.length > 0) {
    return (
      <div className="flex flex-col gap-6">
        <Typography variant="pageTitle">{title}</Typography>
        <div className="max-w-[620px] py-6">
          <DelayedNavigationDisclaimer
            message={t('errors.already_a_grid_meter')}
          />
        </div>
      </div>
    );
  }

  if (!isGatewayOnline) {
    return (
      <div className="flex flex-col gap-6">
        <Typography variant="pageTitle">{title}</Typography>
        <div className="max-w-[620px] py-6">
          <DelayedNavigationDisclaimer
            message={t('tooltips.tgp_offline.description')}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-6">
        <Typography variant="pageTitle">{title}</Typography>
        <GridMeterManufacturerAndTypeCard
          isDisabled={isProcessing}
          isUnsupportedType={!isLoading && isUnsupportedProductType}
        />
        {productTypeIdentifier && (
          <>
            <GridMeterProtocolCard
              isDisabled={isProcessing}
              isLoading={isLoading}
              protocols={protocols}
            />
            {!isLoading && selectedProtocol && parameters && (
              <GridMeterParameters
                parameters={parameters}
                extraParameters={deviceModels?.extraParameters}
                isLoading={isLoading}
                isDisabled={isProcessing}
              />
            )}
          </>
        )}
      </div>
      <GridMeterActionButtons
        isProcessing={isProcessing}
        isConfirmDisabled={!isValidForm}
        onConfirm={handleAddGridMeter}
      />
    </>
  );
};
