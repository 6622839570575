import { useMemo } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import useProductTypeDeviceModels from '@api/sites/devices/use-product-type-device-models';
import { DeleteGridMeterButton } from '@components/grid-meters/DeleteGridMeterButton';
import { filterConfigurableParameters } from '@context/GridMeterContext';
import { useReadOnly } from '@context/ReadOnlyContext';
import EditIcon from '@icons/edit_16.svg';

import { Button, Card, Typography } from '@destination/components';

import { GridMeter as GridMeterModel } from '@omnis-pulse-types';

export const GridMeter = () => {
  const { id = '' } = useParams();
  const gridMeter = useOutletContext<GridMeterModel>();
  const navigate = useNavigate();

  const { t, t_generic } = usePortalTranslation('gridMeters');

  const { isReadOnly } = useReadOnly();
  const { deviceModels, isLoading } = useProductTypeDeviceModels(
    id,
    gridMeter.parameters.productIdentity
  );

  const parameters = useMemo(
    () =>
      filterConfigurableParameters(
        deviceModels?.variants.find(
          variant => variant.name === gridMeter.parameters.variant.name
        )?.parameters || []
      ),
    [deviceModels?.variants, gridMeter.parameters.variant.name]
  );

  const parameterCardStyles =
    '[&>div:nth-child(odd)]:bg-[#FAFAFA] [&>div]:flex [&>div]:flex-col [&>div]:justify-between [&>div]:gap-y-2 [&>div]:rounded-[4px] [&>div]:py-2 [&>div]:pl-3 [&>div]:pr-[18px] [&>div]:md:flex-row [&>div]:md:items-center';

  const ParameterRow = ({
    label,
    value
  }: {
    label: string;
    value: string | number | undefined;
  }) => (
    <div data-testid={`parameter-row-${label}`}>
      <Typography>{label}</Typography>
      <Typography
        variant="smallTitle"
        className="w-full max-w-[342px]"
        data-testid={`${label}-value`}
      >
        {value}
      </Typography>
    </div>
  );

  const SectionHeader = ({
    title,
    action
  }: {
    title: string;
    action?: JSX.Element;
  }) => (
    <div
      className="flex w-full items-baseline justify-between"
      data-testid={`section-header-${title}`}
    >
      <Typography className="mb-4" variant="smallTitle">
        {title}
      </Typography>
      {action}
    </div>
  );

  return (
    <div className="max-w-[620px]" data-testid="grid-meter">
      <SectionHeader
        title={t_generic('literals.configuration', { titleize: true })}
        action={
          <DeleteGridMeterButton
            isDisabled={isReadOnly}
            gridMeter={gridMeter}
          />
        }
      />
      <Card className="mb-12 py-6" data-testid="configuration-card">
        <div className={parameterCardStyles}>
          <ParameterRow
            label={t_generic('literals.manufacturer', { titleize: true })}
            value={gridMeter.manufacturer}
          />
          <ParameterRow
            label={t_generic('literals.type', { titleize: true })}
            value={gridMeter.parameters.type}
          />
          <ParameterRow
            label={t_generic('literals.protocol', { titleize: true })}
            value={t(`protocols.${gridMeter.parameters.variant.name}`, {
              titleize: true
            })}
          />
        </div>
      </Card>

      <SectionHeader
        title={t_generic('labels.entity_settings', {
          entity: t_generic('literals.communication'),
          titleize: true
        })}
        action={
          <Button
            icon={<EditIcon />}
            variant="discreet"
            disabled={isReadOnly}
            onClick={() => navigate('update')}
            data-testid="edit-button"
          >
            {t_generic('buttons.edit', {
              entity: t_generic('literals.settings_other'),
              titleize: true
            })}
          </Button>
        }
      />
      <Card className="py-6" data-testid="communication-settings-card">
        <div className={parameterCardStyles}>
          {!isLoading &&
            parameters?.map(param => (
              <ParameterRow
                key={param.name}
                label={t_generic(`literals.${param.name}`, { titleize: true })}
                value={
                  gridMeter.parameters.variant.parameters?.find(
                    p => p.name === param.name
                  )?.value ?? param.defaultValue
                }
              />
            ))}
        </div>
      </Card>

      {!isLoading &&
        deviceModels?.extraParameters &&
        deviceModels.extraParameters.length > 0 && (
          <>
            <SectionHeader
              title={t_generic('labels.entity_settings', {
                entity: t_generic('literals.special'),
                titleize: true
              })}
            />
            <Card className="py-6" data-testid="special-settings-card">
              <div className={parameterCardStyles}>
                {deviceModels.extraParameters.map(param => (
                  <ParameterRow
                    key={param.name}
                    label={t_generic(`literals.${param.name}`, {
                      titleize: true
                    })}
                    value={
                      gridMeter.parameters.extraParameters?.find(
                        p => p.name === param.name
                      )?.value ?? param.defaultValue
                    }
                  />
                ))}
              </div>
            </Card>
          </>
        )}
    </div>
  );
};
