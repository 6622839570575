import { usePortalTranslation } from '@hooks/use-portal-translation';

import { Chargers } from '@components/sites/site/insights/Chargers';
import { CurrentInsights } from '@components/sites/site/insights/site-level/CurrentInsights';
import { EvseStatusOverview } from '@components/sites/site/insights/site-level/evse-status-chart/EvseStatusOverview';
import { InsightsHeader } from '@components/sites/site/insights/site-level/InsightsHeader';
import { PowerInsights } from '@components/sites/site/insights/site-level/PowerInsights';
import {
  InsightsProvider,
  UNIT_AMPERE,
  useInsightsContext
} from '@context/InsightsContext';

import { Typography } from '@destination/components';

const InsightsContent = () => {
  const { t_generic } = usePortalTranslation('chargers');
  const { activeUnit, aggregatedTelemetry, isLoading, isLoading_all } =
    useInsightsContext();

  return (
    <>
      <Typography className="mb-5" variant="sectionTitle">
        {t_generic('literals.insights', { titleize: true })}
      </Typography>

      <InsightsHeader isLoading={isLoading_all} />

      <div id="insights" className="flex flex-col gap-12">
        {activeUnit === UNIT_AMPERE ? (
          <CurrentInsights
            data={isLoading ? [] : aggregatedTelemetry}
            isLoading={isLoading_all}
          />
        ) : (
          <PowerInsights
            data={isLoading ? [] : aggregatedTelemetry}
            isLoading={isLoading_all}
          />
        )}
        <EvseStatusOverview />
        <Chargers />
      </div>
    </>
  );
};

export const Insights = () => {
  return (
    <InsightsProvider>
      <InsightsContent />
    </InsightsProvider>
  );
};
