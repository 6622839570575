import useSWR from 'swr';

import { SITE_FALLBACK_BUDGET_PATH } from '@api/paths';

import { SiteFallbackBudget } from '@omnis-pulse-types';

export default function useSiteLoadManagementFallbackBudget(siteId?: string) {
  const { data, isLoading, error, mutate } = useSWR<SiteFallbackBudget>(
    siteId ? SITE_FALLBACK_BUDGET_PATH(siteId) : null
  );

  return {
    data,
    isLoading,
    isError: Boolean(error),
    mutate
  };
}
