import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';

import useSiteGridMeter from '@api/sites/use-site-grid-meter';
import useSiteLoadManagementLimits from '@api/sites/use-site-load-management-limits';
import { useReadOnly } from '@context/ReadOnlyContext';

import {
  DataError,
  Disclaimer,
  LoadingSkeleton
} from '@destination/components';

import { ActiveLoadManagementMode } from '@omnis-pulse-types';

import { GridConnectionLimit } from '../GridConnectionLimit';
import { FallbackChargingBudgetLimit } from './FallbackChargingBudgetLimit';

export const DynamicLoadManagement = () => {
  const { t } = usePortalTranslation('siteSettings');
  const { id: siteId } = useParams();
  const {
    data,
    isLoading: isLoadingLimits,
    isError: isErrorLimits,
    mutate: mutateLimits,
    isUpdating,
    update
  } = useSiteLoadManagementLimits(siteId);
  const {
    gridMeters,
    isLoading: isLoadingGridMeter,
    isError: isErrorGridMeter,
    mutate: mutateGridMeter
  } = useSiteGridMeter(siteId);
  const { isReadOnly } = useReadOnly();

  if (!data) return null;

  const isDisabled =
    isReadOnly ||
    isUpdating ||
    data.activeMode === ActiveLoadManagementMode.STATIC ||
    data.activeMode === ActiveLoadManagementMode.EXTERNAL;

  const renderContent = () => {
    if (isErrorLimits) {
      return <DataError onRefresh={() => mutateLimits(undefined, true)} />;
    }
    if (isErrorGridMeter) {
      return <DataError onRefresh={() => mutateGridMeter(undefined, true)} />;
    }
    if (isLoadingLimits || isLoadingGridMeter) {
      return (
        <div className="mt-2">
          <LoadingSkeleton className="rounded" height={56} />
        </div>
      );
    }

    return (
      <div className="mt-2">
        <div className="py-2">
          {(gridMeters || []).length === 0 &&
            data.activeMode === ActiveLoadManagementMode.DYNAMIC && (
              <Disclaimer
                variant="warning"
                message={t('mode.warning_message')}
              />
            )}
        </div>
        <div className="flex min-h-[56px] rounded-l-sm rounded-r-sm bg-[#FAFAFA] px-3 py-2">
          <GridConnectionLimit
            value={data.dynamicGridConnectionLimit}
            activeMode={ActiveLoadManagementMode.DYNAMIC}
            isDisabled={isDisabled}
            onSave={update}
          />
        </div>
        <div className="flex min-h-[56px] rounded-l-sm rounded-r-sm px-3 py-2">
          <FallbackChargingBudgetLimit
            value={data.fallbackChargingBudgetLimit}
            maxValue={data.dynamicGridConnectionLimit}
            isDisabled={isDisabled}
            onSave={update}
          />
        </div>
      </div>
    );
  };

  return renderContent();
};
