import { useMemo } from 'react';

import { TOverloadedFunction } from '@hooks/use-portal-translation';
import { TooltipProps } from 'recharts';

import { CustomFloatingTooltip } from '@components/charts/CustomFloatingTooltip';
import { TooltipItem } from '@components/charts/TootltipItem';
import { useInsightsContext } from '@context/InsightsContext';

import { SiteTelemetryPoint } from '@omnis-pulse-types';

import {
  buildCommonTooltipItems,
  PHASE_2,
  PHASE_3
} from '../site-insights-tooltip-utils';

interface CurrentTelemetryPoint extends Omit<SiteTelemetryPoint, 'timestamp'> {
  timestamp: number | string;
  current_l1_range: [number, number];
  charger_l1_range: [number, number];
  current_l2_range: [number, number];
  charger_l2_range: [number, number];
  current_l3_range: [number, number];
  charger_l3_range: [number, number];
}

interface RangeTooltipProps
  extends TooltipProps<number | [number, number], string> {
  isStaticMode: boolean;
  t: TOverloadedFunction;
  activePhase: string;
  chartData: CurrentTelemetryPoint[];
}

export const CurrentDeliveredGraphTooltip = ({
  active,
  payload,
  label,
  isStaticMode,
  t,
  activePhase,
  chartData
}: RangeTooltipProps) => {
  const { isPeakCapacityEnabled, isLocalModbusEnabled } = useInsightsContext();

  const completePayload = useMemo<TooltipItem[]>(() => {
    if (
      !payload ||
      payload.length === 0 ||
      !chartData ||
      chartData.length === 0
    ) {
      return [];
    }

    const timestampStr = String(label);

    const exactDataPoint = chartData.find(
      point => String(point.timestamp) === timestampStr
    );

    const data = exactDataPoint || payload[0]?.payload || {};

    // Check if we have valid data
    if (Object.keys(data).length === 0) {
      return [];
    }

    const { rangeKey, chargerRangeKey, chargerBudgetKey } =
      getKeysForActivePhase(activePhase);

    const { residualValue, chargerValue } = calculateBaseValues(
      data,
      rangeKey,
      chargerRangeKey
    );

    const hasGridLimit =
      data.grid_limit_current !== undefined && data.grid_limit_current !== null;
    const hasLoadShedding =
      isLocalModbusEnabled &&
      data.load_shedding_current !== undefined &&
      data.load_shedding_current !== null;
    const hasChargerBudget =
      isStaticMode &&
      data[chargerBudgetKey] !== undefined &&
      data[chargerBudgetKey] !== null;
    const hasPeakCapacity =
      isPeakCapacityEnabled &&
      data.peak_capacity_limit !== undefined &&
      data.peak_capacity_limit !== null;

    if (
      residualValue === 0 &&
      chargerValue === 0 &&
      !hasGridLimit &&
      !hasLoadShedding &&
      !hasChargerBudget &&
      !hasPeakCapacity
    ) {
      return [];
    }

    const residualName = t('labels.entity_load', {
      entity: t('literals.residual'),
      titleize: true
    });

    const chargerName = t('labels.entity_consumption', {
      entity: t('literals.charger_other'),
      titleize: true
    });

    const gridLimitName = t('literals.grid_limit', { titleize: true });

    const loadSheddingName = t('labels.entity_limit', {
      entity: t('literals.modbus'),
      titleize: true
    });

    const chargingBudgetName = t('labels.entity_limit', {
      entity: t('literals.charging_budget'),
      titleize: true
    });

    const peakCapacityName = t('labels.entity_limit', {
      entity: t('literals.peak_capacity'),
      titleize: true
    });

    return buildCommonTooltipItems({
      residualConfig: {
        key: rangeKey,
        value: residualValue,
        unit: 'A',
        name: residualName
      },
      chargerConfig: {
        key: chargerRangeKey,
        value: chargerValue,
        unit: 'A',
        name: chargerName
      },
      gridLimitConfig: {
        key: 'grid_limit_current',
        value: data.grid_limit_current,
        unit: 'A',
        name: gridLimitName
      },
      conditionalConfigs: {
        loadShedding: {
          key: 'load_shedding_current',
          value: data.load_shedding_current,
          unit: 'A',
          isEnabled: isLocalModbusEnabled,
          name: loadSheddingName
        },
        chargerBudget: {
          key: chargerBudgetKey,
          value: data[chargerBudgetKey],
          unit: 'A',
          isEnabled: isStaticMode,
          name: chargingBudgetName
        },
        peakCapacity: {
          key: 'peak_capacity_limit',
          value: data.peak_capacity_limit,
          unit: 'A',
          isEnabled: isPeakCapacityEnabled,
          name: peakCapacityName
        }
      }
    });
  }, [
    payload,
    activePhase,
    isStaticMode,
    isPeakCapacityEnabled,
    isLocalModbusEnabled,
    t,
    label,
    chartData
  ]);

  if (!active || completePayload.length === 0) {
    return null;
  }

  const tooltipKey = `tooltip-${label}`;

  return (
    <div key={tooltipKey} style={{ pointerEvents: 'none' }}>
      <CustomFloatingTooltip
        active={active}
        label={label}
        payload={completePayload}
      />
    </div>
  );
};

const getKeysForActivePhase = (activePhase: string) => {
  let activePhaseSuffix = '_l1';
  if (activePhase === PHASE_2) activePhaseSuffix = '_l2';
  if (activePhase === PHASE_3) activePhaseSuffix = '_l3';

  return {
    rangeKey: `current${activePhaseSuffix}_range`,
    chargerRangeKey: `charger${activePhaseSuffix}_range`,
    chargerBudgetKey: `charger_budget_current${activePhaseSuffix}`
  };
};

const calculateBaseValues = (
  data: Record<string, unknown>,
  rangeKey: string,
  chargerRangeKey: string
) => {
  const residualValue =
    Array.isArray(data[rangeKey]) && data[rangeKey].length > 1
      ? (data[rangeKey][1] as number) || 0
      : 0;

  const chargerTotal =
    Array.isArray(data[chargerRangeKey]) && data[chargerRangeKey].length > 1
      ? (data[chargerRangeKey][1] as number) || 0
      : 0;

  return {
    residualValue,
    chargerValue: chargerTotal - residualValue
  };
};
