import React, { useMemo, useState } from 'react';

import { getTime } from 'date-fns';
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { EvseStatusAxisTooltipContent } from '@components/sites/site/insights/site-level/evse-status-chart/EvseStatusAxisTooltipContent';
import { useInsightsContext } from '@context/InsightsContext';
import { formatDate, timeFormat } from '@utils/date-utils';
import {
  calculateYAxisTicks,
  processData
} from '@utils/graphs/evse-status-graph-data-processor-utils';
import { renderCustomYAxisTick } from '@utils/graphs/evse-status-graph-renderer-utils';
import { getTicksForInterval } from '@utils/insights-utils';

import { Tooltip as DestinationTooltip } from '@destination/components';

import { Device, EvseStatusEnum } from '@omnis-pulse-types';

export type DataPoint = {
  x: number;
  subgroup: number;
  group: string;
  z: number;
  status: EvseStatusEnum;
  energy?: number;
  chargerName?: string;
};

interface IHorizontalBarChart {
  data: DataPoint[];
  ShapeComponent: React.ComponentType<{
    yAxisTicks: { label: string; y: number }[];
  }>;
  TooltipComponent: React.ComponentType;
  chargers: Device[];
}

export const HorizontalBarChart = ({
  data,
  ShapeComponent,
  TooltipComponent,
  chargers
}: IHorizontalBarChart) => {
  const [hoveredAxisCharger, setHoveredAxisCharger] = useState<string>();
  const [evseId, setEvseId] = useState<string>();

  const processedData = processData(data);
  const yAxisTicks = calculateYAxisTicks(processedData);
  const { range } = useInsightsContext();

  const domain = useMemo<[number, number]>(() => {
    return [getTime(range[0]), getTime(range[1])];
  }, [range]);

  const chargerId = useMemo(() => {
    if (hoveredAxisCharger) {
      let charger;

      charger = chargers.filter(charger => charger.id === hoveredAxisCharger);

      if (charger.length === 0) {
        charger = chargers.filter(
          charger => charger.name === hoveredAxisCharger
        );
      }

      if (charger.length > 0) {
        return charger[0].id; // Default to the first match
      }
    }
  }, [hoveredAxisCharger, chargers]);

  const handleYAxisHover = (value: string) => {
    if (value.includes('_SUBGROUP_')) {
      setEvseId(value.split('_SUBGROUP_')[1]);
    }
    setHoveredAxisCharger(value.split('_SUBGROUP_')[0].split('GROUP_')[1]);
  };

  const handleYAxisLeave = () => {
    setEvseId(undefined);
    setHoveredAxisCharger(undefined);
  };

  return (
    <div
      data-testid="horizontal-bar-chart"
      className="w-full [&>div]:h-full [&>div]:w-full"
      style={{
        height:
          yAxisTicks.length > 0 ? yAxisTicks[yAxisTicks.length - 1].y + 50 : 358
      }}
    >
      <DestinationTooltip
        message={
          hoveredAxisCharger ? (
            <EvseStatusAxisTooltipContent
              chargerId={chargerId}
              evseId={evseId}
            />
          ) : null
        }
      >
        <ResponsiveContainer height="100%" width="100%">
          <ScatterChart>
            <CartesianGrid
              strokeDasharray="5 5"
              stroke="#EBEBEB"
              horizontal={false}
            />
            <XAxis
              dataKey="x"
              type="number"
              scale="time"
              domain={domain}
              stroke={'#EBEBEB'}
              tickFormatter={tick => formatDate(tick, timeFormat)}
              tick={{ fontSize: 12, fill: '#9F9F9F', fontFamily: 'ABBvoice' }}
              ticks={getTicksForInterval(domain)}
              tickLine={false}
            />
            <YAxis
              type="category"
              allowDuplicatedCategory={false}
              dataKey="y"
              tickLine={false}
              interval={0}
              tick={props => renderCustomYAxisTick(props, yAxisTicks)}
              stroke={'#EBEBEB'}
              padding={{ top: 16 }}
              width={92}
              onMouseEnter={({ value }) => handleYAxisHover(value)}
              onMouseLeave={handleYAxisLeave}
            />
            <Scatter
              data={processedData.toReversed()}
              shape={<ShapeComponent yAxisTicks={yAxisTicks} />}
              isAnimationActive={false}
            />
            <Tooltip
              isAnimationActive={false}
              offset={0}
              content={<TooltipComponent />}
              cursor={false}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </DestinationTooltip>
    </div>
  );
};
