import { SITE_LOCAL_MODBUS_INTERFACE_PATH } from '@api/paths';
import { modifier } from '@utils/swr-utils';

import { UpdateSiteLocalInterfaceOptions } from '@omnis-pulse-types';

export default async function updateSiteLocalInterfaceOptions(
  siteId: string,
  payload: UpdateSiteLocalInterfaceOptions
) {
  const url = `${SITE_LOCAL_MODBUS_INTERFACE_PATH(siteId)}/options`;
  return await modifier(url, 'PATCH', payload);
}
