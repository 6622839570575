import { useCallback, useEffect, useMemo } from 'react';

import { getTime } from 'date-fns';

import useSiteAggregatedTelemetry from '@api/sites/use-site-aggregated-telemetry';
import useSiteEnergyManagementPeakCapacity from '@api/sites/use-site-energy-management-peak-capacity';
import useSiteLoadManagementLimits from '@api/sites/use-site-load-management-limits';
import useSiteLocalModbusInterface from '@api/sites/use-site-local-modbus-interface';

import { SiteTelemetryPoint } from '@omnis-pulse-types';

interface UseSiteInsightsDataProps {
  siteId: string;
  startTimeISO: string;
  endTimeISO: string;
  setActiveLoadManagementMode: (mode: string | undefined) => void;
  setIsPeakCapacityEnabled: (enabled: boolean) => void;
  setPeakCapacityLimit: (limit: number | null) => void;
  setIsLocalModbusEnabled: (enabled: boolean) => void;
}

export interface ProcessedTelemetryPoint
  extends Omit<SiteTelemetryPoint, 'timestamp'> {
  timestamp: number;
}

interface SiteInsightsData {
  aggregatedTelemetry: ProcessedTelemetryPoint[];
  isLoading: boolean;
  isLoading_all: boolean;
  refetchAndInvalidateData: () => void;
}

export const useSiteInsightsData = ({
  siteId,
  startTimeISO,
  endTimeISO,
  setActiveLoadManagementMode,
  setIsPeakCapacityEnabled,
  setPeakCapacityLimit,
  setIsLocalModbusEnabled
}: UseSiteInsightsDataProps): SiteInsightsData => {
  // Fetch telemetry data
  const { data, isLoading, mutate, invalidateCache } =
    useSiteAggregatedTelemetry(siteId, startTimeISO, endTimeISO);

  // Fetch load management limits
  const { data: loadManagementData, isLoading: isLoadingLoadManagement } =
    useSiteLoadManagementLimits(siteId);

  // Fetch energy management peak capacity
  const { data: peakCapacityData, isLoading: isLoadingPeakCapacity } =
    useSiteEnergyManagementPeakCapacity(siteId);

  // Fetch local modbus interface status
  const { data: localModbusInterface, isLoading: isLoadingLocalModbus } =
    useSiteLocalModbusInterface(siteId);

  const isLoading_all = useMemo(
    () =>
      isLoading ||
      isLoadingLoadManagement ||
      isLoadingPeakCapacity ||
      isLoadingLocalModbus,
    [
      isLoading,
      isLoadingLoadManagement,
      isLoadingPeakCapacity,
      isLoadingLocalModbus
    ]
  );

  const aggregatedTelemetry = useMemo(() => {
    if (!data) return [];

    return data.map(item => ({
      ...item,
      timestamp: getTime(new Date(item.timestamp))
    }));
  }, [data]);

  const refetchAndInvalidateData = useCallback(() => {
    invalidateCache();
    mutate();
  }, [mutate, invalidateCache]);

  useEffect(() => {
    if (loadManagementData?.activeMode) {
      setActiveLoadManagementMode(loadManagementData.activeMode);
    }
  }, [loadManagementData, setActiveLoadManagementMode]);

  useEffect(() => {
    if (peakCapacityData) {
      setIsPeakCapacityEnabled(peakCapacityData.isEnabled);
      setPeakCapacityLimit(
        peakCapacityData.isEnabled ? peakCapacityData.power : null
      );
    }
  }, [peakCapacityData, setIsPeakCapacityEnabled, setPeakCapacityLimit]);

  useEffect(() => {
    if (localModbusInterface) {
      setIsLocalModbusEnabled(localModbusInterface.isEnabled);
    }
  }, [localModbusInterface, setIsLocalModbusEnabled]);

  return {
    aggregatedTelemetry,
    isLoading,
    isLoading_all,
    refetchAndInvalidateData
  };
};
