import { TooltipItem } from '@components/charts/TootltipItem';

import { COLORS } from './site-insights-colors';

export const PHASE_1 = 'phase_1';
export const PHASE_2 = 'phase_2';
export const PHASE_3 = 'phase_3';

export const buildCommonTooltipItems = ({
  residualConfig,
  chargerConfig,
  gridLimitConfig,
  conditionalConfigs
}: {
  residualConfig: {
    key: string;
    value: number;
    unit: string;
    name: string;
  };
  chargerConfig: {
    key: string;
    value: number;
    unit: string;
    name: string;
  };
  gridLimitConfig: {
    key: string;
    value: number | undefined | null;
    unit: string;
    name: string;
  };
  conditionalConfigs: {
    loadShedding?: {
      key: string;
      value: number | undefined | null;
      unit: string;
      isEnabled: boolean;
      name: string;
    };
    chargerBudget?: {
      key: string;
      value: number | undefined | null;
      unit: string;
      isEnabled: boolean;
      name: string;
    };
    peakCapacity?: {
      key: string;
      value: number | undefined | null;
      unit: string;
      isEnabled: boolean;
      name: string;
    };
  };
}): TooltipItem[] => {
  const tooltipItems: TooltipItem[] = [
    // Residual item
    {
      dataKey: residualConfig.key,
      name: residualConfig.name,
      value: residualConfig.value,
      stroke: COLORS.RESIDUAL,
      fill: COLORS.RESIDUAL,
      unit: residualConfig.unit
    },
    {
      dataKey: chargerConfig.key,
      name: chargerConfig.name,
      value: chargerConfig.value,
      stroke: COLORS.CHARGERS,
      fill: COLORS.CHARGERS,
      unit: chargerConfig.unit
    }
  ];

  if (gridLimitConfig.value !== undefined && gridLimitConfig.value !== null) {
    tooltipItems.push({
      dataKey: gridLimitConfig.key,
      name: gridLimitConfig.name,
      value: gridLimitConfig.value,
      stroke: COLORS.GRID_LIMIT,
      unit: gridLimitConfig.unit,
      fill: ''
    });
  }

  const { loadShedding, chargerBudget, peakCapacity } = conditionalConfigs;

  if (
    loadShedding?.isEnabled &&
    loadShedding.value !== undefined &&
    loadShedding.value !== null
  ) {
    tooltipItems.push({
      dataKey: loadShedding.key,
      name: loadShedding.name,
      value: loadShedding.value,
      stroke: COLORS.LOAD_SHEDDING,
      unit: loadShedding.unit,
      fill: ''
    });
  }

  if (
    chargerBudget?.isEnabled &&
    chargerBudget.value !== undefined &&
    chargerBudget.value !== null
  ) {
    tooltipItems.push({
      dataKey: chargerBudget.key,
      name: chargerBudget.name,
      value: chargerBudget.value,
      stroke: COLORS.CHARGING_BUDGET,
      unit: chargerBudget.unit,
      fill: ''
    });
  }

  if (
    peakCapacity?.isEnabled &&
    peakCapacity.value !== undefined &&
    peakCapacity.value !== null
  ) {
    tooltipItems.push({
      dataKey: peakCapacity.key,
      name: peakCapacity.name,
      value: peakCapacity.value,
      stroke: COLORS.PEAK_CAPACITY,
      unit: peakCapacity.unit,
      fill: '',
      strokeDasharray: '4 4'
    });
  }

  return tooltipItems;
};
