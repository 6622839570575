import { useLayoutEffect, useMemo, useState } from 'react';

import {
  TOverloadedFunction,
  usePortalTranslation
} from '@hooks/use-portal-translation';
import { getTime } from 'date-fns';
import { Area, CartesianGrid, Line, Tooltip, XAxis, YAxis } from 'recharts';

import { ComposedGraph } from '@components/charts/ComposedGraph';
import { CustomCursor } from '@components/charts/CustomCursor';
import { CustomYAxisTick } from '@components/charts/CustomYAxisTick';
import { useInsightsContext } from '@context/InsightsContext';
import { formatDate, timeFormat } from '@utils/date-utils';
import { getTicksForInterval } from '@utils/insights-utils';

import { LoadingSkeleton } from '@destination/components';

import {
  ActiveLoadManagementMode,
  SiteTelemetryPoint
} from '@omnis-pulse-types';

import { CurrentOrEnergyDeliveredLegend } from '../CurrentOrEnergyDeliveredLegend';
import { COLORS } from '../site-insights-colors';
import { EnergyDeliveredGraphTooltip } from './EnergyDeliveredGraphTooltip';

export type AggregatedData = (Omit<SiteTelemetryPoint, 'timestamp'> & {
  timestamp: number;
})[];
interface IAggregatedData {
  data: AggregatedData;
  isLoading: boolean;
}

type DataKey =
  | 'chargers_power'
  | 'residual_power'
  | 'grid_limit_power'
  | 'load_shedding_power'
  | 'charger_budget_power_total'
  | 'peak_capacity_limit';

export type VisibilityState = Record<DataKey, boolean>;

export const EnergyDeliveredGraph = ({ data, isLoading }: IAggregatedData) => {
  const { t } = usePortalTranslation();
  const {
    range,
    handleZoom,
    handleResetZoom,
    activeLoadManagementMode,
    isPeakCapacityEnabled,
    peakCapacityLimit,
    isLocalModbusEnabled
  } = useInsightsContext();

  const isStaticMode =
    activeLoadManagementMode === ActiveLoadManagementMode.STATIC;

  const [visibleLines, setVisibleLines] = useState<VisibilityState>({
    chargers_power: true,
    residual_power: true,
    grid_limit_power: true,
    load_shedding_power: isLocalModbusEnabled,
    charger_budget_power_total: isStaticMode,
    peak_capacity_limit: isPeakCapacityEnabled
  });

  const axis = useMemo(() => {
    return getAxis([getTime(range[0]), getTime(range[1])]);
  }, [range]);

  const processedData = useMemo(() => {
    return data.map(dataPoint => {
      // Sum the active power across all three phases for both chargers and residual
      const chargers_power_raw =
        (dataPoint.chargers_power_l1 ?? 0) +
        (dataPoint.chargers_power_l2 ?? 0) +
        (dataPoint.chargers_power_l3 ?? 0);

      const residual_power_raw =
        (dataPoint.residual_power_l1 ?? 0) +
        (dataPoint.residual_power_l2 ?? 0) +
        (dataPoint.residual_power_l3 ?? 0);

      // Sum charger budget power across all phases and convert to kW
      const charger_budget_power_total =
        (dataPoint.charger_budget_power ?? 0) / 1000;

      const load_shedding_power = dataPoint.load_shedding_power
        ? dataPoint.load_shedding_power / 1000
        : undefined;

      // Add peak capacity limit in kW if enabled
      const peak_capacity_limit_kw =
        isPeakCapacityEnabled && peakCapacityLimit !== null
          ? peakCapacityLimit / 1000
          : undefined;

      return {
        ...dataPoint,
        chargers_power_raw,
        residual_power_raw,
        grid_limit_power_raw: dataPoint.grid_limit_power,

        chargers_power: chargers_power_raw / 1000,
        residual_power: residual_power_raw / 1000,
        grid_limit_power: dataPoint.grid_limit_power
          ? dataPoint.grid_limit_power / 1000
          : undefined,
        load_shedding_power,
        charger_budget_power_total: charger_budget_power_total || undefined,
        peak_capacity_limit: peak_capacity_limit_kw
      };
    });
  }, [data, isPeakCapacityEnabled, peakCapacityLimit]);

  const rangeData = useMemo(() => {
    return processedData.map(point => {
      return {
        ...point,
        residual_power_range: [0, point.residual_power ?? 0],
        chargers_power_range: [
          point.residual_power ?? 0,
          (point.residual_power ?? 0) + (point.chargers_power ?? 0)
        ]
      };
    });
  }, [processedData]);

  useLayoutEffect(() => {
    setVisibleLines(prevState => ({
      ...prevState,
      charger_budget_power_total: isStaticMode,
      peak_capacity_limit: isPeakCapacityEnabled,
      load_shedding_power: isLocalModbusEnabled
    }));
  }, [isStaticMode, isPeakCapacityEnabled, isLocalModbusEnabled]);

  return (
    <div className="flex h-[427px] flex-col">
      <div className="relative flex-auto" data-testid="energy-delivered-graph">
        {isLoading && (
          <div className="absolute inset-0 z-50 mb-[33px] ml-[94px] mr-1 mt-1 bg-white">
            <LoadingSkeleton className="!h-full rounded-[10px]" height={0} />
          </div>
        )}
        <ComposedGraph
          onZoom={handleZoom}
          onResetZoom={handleResetZoom}
          hideCartesianGrid
        >
          <CartesianGrid strokeDasharray="5 5" stroke="#EBEBEB" />
          {axis}
          <Tooltip
            allowEscapeViewBox={{ x: true, y: true }}
            offset={0}
            isAnimationActive={false}
            shared={true}
            content={<EnergyDeliveredGraphTooltip t={t} />}
            cursor={processedData.length ? <CustomCursor /> : <></>}
            wrapperStyle={{ zIndex: 100, pointerEvents: 'none' }}
          />
          <Area
            data={rangeData}
            key="residual_power"
            dataKey="residual_power_range"
            name={`${t('labels.entity_load', { entity: t('literals.residual'), titleize: true })}`}
            fill={COLORS.RESIDUAL}
            stroke={COLORS.RESIDUAL}
            fillOpacity={0.6}
            unit="kW"
            type="monotone"
            isAnimationActive={false}
            connectNulls={true}
          />
          <Area
            data={rangeData}
            key="chargers_power"
            dataKey="chargers_power_range"
            name={`${t('labels.entity_consumption', { titleize: true, entity: t('literals.charger_other') })}`}
            fill={COLORS.CHARGERS}
            stroke={COLORS.CHARGERS}
            fillOpacity={0.6}
            unit="kW"
            type="monotone"
            isAnimationActive={false}
            connectNulls={true}
          />
          {lines(processedData, t, isPeakCapacityEnabled).filter(
            line => visibleLines[line.props.dataKey as DataKey]
          )}
        </ComposedGraph>
      </div>
      <div className="flex-initial">
        <CurrentOrEnergyDeliveredLegend />
      </div>
    </div>
  );
};

const getAxis = (domain: [number, number]) => [
  <XAxis
    key={1}
    dataKey="timestamp"
    type="number"
    scale="time"
    domain={domain}
    stroke={'#EBEBEB'}
    tickFormatter={tick => formatDate(tick, timeFormat)}
    tick={{ fontSize: 12, fill: '#9F9F9F', fontFamily: 'ABBvoice' }}
    ticks={getTicksForInterval(domain)}
    tickLine={false}
  />,
  <YAxis
    key={2}
    stroke={'#EBEBEB'}
    padding={{ top: 16 }}
    tickFormatter={value => `${value} kW`}
    tick={<CustomYAxisTick />}
    tickLine={false}
    width={92}
  />
];

interface LineProps {
  dataKey: string;
  name: string;
  stroke: string;
  strokeWidth: number;
  dot: boolean;
  strokeDasharray?: string;
}

const lines = (
  data: AggregatedData,
  t: TOverloadedFunction,
  isPeakCapacityEnabled: boolean
) => {
  const lineConfigs: LineProps[] = [
    {
      dataKey: 'grid_limit_power',
      name: `${t('literals.grid_limit', { titleize: true })}`,
      stroke: COLORS.GRID_LIMIT,
      strokeWidth: 2,
      dot: false
    },
    {
      dataKey: 'load_shedding_power',
      name: `${t('labels.entity_limit', { entity: t('literals.modbus'), titleize: true })}`,
      stroke: COLORS.LOAD_SHEDDING,
      strokeWidth: 2,
      dot: false
    },
    {
      dataKey: 'charger_budget_power_total',
      name: `${t('labels.entity_limit', { entity: t('literals.charging_budget'), titleize: true })}`,
      stroke: COLORS.CHARGING_BUDGET,
      strokeWidth: 2,
      dot: false
    }
  ];

  // Add peak capacity limit line if enabled
  if (isPeakCapacityEnabled) {
    lineConfigs.push({
      dataKey: 'peak_capacity_limit',
      name: `${t('labels.entity_limit', { entity: t('literals.peak_capacity'), titleize: true })}`,
      stroke: COLORS.PEAK_CAPACITY,
      strokeWidth: 2,
      strokeDasharray: '4 4',
      dot: false
    });
  }

  return lineConfigs.map((line: LineProps, index: number) => (
    <Line
      data={data}
      key={`line-${index + 1}`}
      {...line}
      unit="kW"
      type="monotone"
      isAnimationActive={false}
      connectNulls={true}
    />
  ));
};
