import { usePortalTranslation } from '@hooks/use-portal-translation';
import { ProcessedTelemetryPoint } from '@hooks/use-site-insights-data';

import { EnergyDeliveredGraph } from '@components/sites/site/insights/site-level/energy-delivered-graph/EnergyDeliveredGraph';

import { Card, Typography } from '@destination/components';

interface IPowerInsights {
  data: ProcessedTelemetryPoint[];
  isLoading: boolean;
}

export const PowerInsights = ({ data, isLoading }: IPowerInsights) => {
  const { t } = usePortalTranslation('chargers');

  return (
    <div>
      <Typography className="mb-4" variant="smallTitle">
        {t('literals.energy_delivered', { titleize: true })}
      </Typography>
      <Card className="mt-16">
        <EnergyDeliveredGraph data={data} isLoading={isLoading} />
      </Card>
    </div>
  );
};
