import React from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';

import useChargerDetails from '@api/sites/chargers/use-charger-details';
import { getSocketTypeIcon, getSocketTypeLabel } from '@utils/socket-utils';

import { LoadingIndicator } from '@destination/components';

import { ChargerDetails, SocketTypeEnum } from '@omnis-pulse-types';

export interface IEvseStatusAxisTooltipContent {
  chargerId?: string;
  evseId?: string;
}

export const EvseStatusAxisTooltipContent = ({
  chargerId,
  evseId
}: IEvseStatusAxisTooltipContent) => {
  const { id: siteId } = useParams();
  const { charger } = useChargerDetails(siteId, chargerId, {
    revalidateIfStale: false
  });

  if (!charger) {
    return <LoadingIndicator />;
  }

  if (evseId) {
    const { evseStatuses } = charger;
    const evse = evseStatuses.find(
      evseStatus => evseStatus.id === Number(evseId)
    );

    if (!evse) {
      return null;
    }

    return (
      <EvseStatus
        socketTypes={evse.sockets.map(socket => socket.type)}
        evseId={evseId}
      />
    );
  }

  return <ChargerStatus charger={charger} />;
};

interface IChargerStatus {
  charger: ChargerDetails;
}

const ChargerStatus = ({ charger }: IChargerStatus) => {
  const { t } = usePortalTranslation();
  const { t: t_chargers } = usePortalTranslation('chargers');

  const chargerDetailsLabel = t('labels.entity_details', {
    entity: t('literals.charger', { titleize: true })
  });
  const serialNumberLabel = t('literals.serial_number', {
    titleize: true
  });
  const productTypeLabel = t('literals.product_type', {
    titleize: true
  });
  const maxInputCurrentLabel = t_chargers('literals.max_input_current', {
    titleize: true
  });

  return (
    <div className="flex flex-col gap-2 text-sm">
      <span className="font-bold">{chargerDetailsLabel}</span>
      <div className="grid grid-cols-[auto,auto] gap-3">
        <span>{serialNumberLabel}</span>
        <span className="font-bold">{charger.serialNumber}</span>
        <span>{productTypeLabel}</span>
        <span className="font-bold">{charger.productType}</span>
        {charger.safetyLimit && (
          <>
            <span>{maxInputCurrentLabel}</span>
            <span className="font-bold">{charger.safetyLimit}</span>
          </>
        )}
      </div>
    </div>
  );
};

interface IEvseStatus {
  socketTypes: SocketTypeEnum[];
  evseId: string;
}

const EvseStatus = ({ socketTypes, evseId }: IEvseStatus) => {
  const { t } = usePortalTranslation();

  const evseDetailsLabel = `EVSE ${evseId} ${t('literals.details')}`;
  const outletTypeLabel = t('labels.entity_type', {
    entity: t('literals.outlet', { titleize: true })
  });

  return (
    <div className="flex flex-col gap-2 text-sm">
      <span className="font-bold">{evseDetailsLabel}</span>
      <div className="grid grid-cols-[auto,auto] gap-3">
        <span>{outletTypeLabel}</span>
        <div className="flex gap-1 text-sm font-bold">
          {socketTypes.map((socketType, index) => (
            <React.Fragment key={`${socketType}-${index}`}>
              <span>{`${getSocketTypeLabel(socketType)}${index < socketTypes.length - 1 ? ', ' : ''}`}</span>
              {getSocketTypeIcon(socketType) && (
                <div>{getSocketTypeIcon(socketType)}</div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
