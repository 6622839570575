import { usePortalTranslation } from '@hooks/use-portal-translation';

import { AutomaticSaveCheckbox } from '@destination/components';

import { SiteLocalInterface } from '@omnis-pulse-types';

export enum ModbusInterfaceOption {
  ExternalController = 'externalController',
  DsoLimit = 'dsoLimit'
}

export interface ILocalModbusInterfaceOptions {
  isDisabled: boolean;
  data: SiteLocalInterface;
  toggleOption: (option: ModbusInterfaceOption) => Promise<void>;
}

export const LocalModbusInterfaceOptions = ({
  isDisabled,
  data,
  toggleOption
}: ILocalModbusInterfaceOptions) => {
  const { t, t_generic } = usePortalTranslation('siteSettings');
  const errorToastMessage = t('failed_to_save_settings', {
    entity: t_generic('literals.local_modbus_interface')
  });
  return (
    <>
      <div className="px-3 py-4">
        <AutomaticSaveCheckbox
          disabled={isDisabled}
          label={t('local_modbus_interface.local_external_interface', {
            titleize: true
          })}
          description={t(
            'local_modbus_interface.local_external_interface_description',
            { titleize: true }
          )}
          initialCheckedValue={data.isExternalControllerEnabled}
          onSave={() => toggleOption(ModbusInterfaceOption.ExternalController)}
          errorToastMessage={errorToastMessage}
        />
      </div>
      <div className="px-3 py-4">
        <AutomaticSaveCheckbox
          disabled={isDisabled}
          label={t(
            'local_modbus_interface.local_temporary_current_limitation',
            { titleize: true }
          )}
          description={t(
            'local_modbus_interface.local_temporary_current_limitation_description',
            { titleize: true }
          )}
          initialCheckedValue={data.isDsoLimitEnabled}
          onSave={() => toggleOption(ModbusInterfaceOption.DsoLimit)}
        />
      </div>
    </>
  );
};
