import { eachHourOfInterval, getTime } from 'date-fns';

export const getTicksForInterval = (domain: [number, number]) => {
  const [start, end] = domain;
  const ticks = eachHourOfInterval({ start, end })
    .filter((_, index) => index % 2 === 0)
    .map(dateObject => getTime(dateObject));

  // If there are less than 2 ticks, return the start and end
  if (ticks.length < 2) {
    return [start, end];
  }

  // Otherwise, set the first and last tick to the start and end
  ticks[0] = start;
  ticks[ticks.length - 1] = end;

  return ticks;
};
