import { useCallback } from 'react';

import { DateTimeFilterButtons } from '@components/sites/site/insights/DateTimeFilterButtons';
import {
  UNIT_AMPERE,
  UNIT_KILOWATTS,
  useInsightsContext
} from '@context/InsightsContext';

import { SegmentControl } from '@destination/components';

interface InsightsHeaderProps {
  isLoading: boolean;
}

const UNIT_TOGGLE_OPTIONS: [
  { label: string; value: string | number },
  { label: string; value: string | number }
] = [
  { label: UNIT_AMPERE, value: UNIT_AMPERE },
  { label: UNIT_KILOWATTS, value: UNIT_KILOWATTS }
];

export const InsightsHeader = ({ isLoading }: InsightsHeaderProps) => {
  const { activeUnit, setActiveUnit } = useInsightsContext();

  const handleUnitChange = useCallback(
    (unit: string | number) => {
      setActiveUnit(unit.toString());
    },
    [setActiveUnit]
  );

  return (
    <div className="flex justify-between">
      <DateTimeFilterButtons isLoading={isLoading} />
      <SegmentControl
        options={UNIT_TOGGLE_OPTIONS}
        selected={activeUnit}
        onChange={handleUnitChange}
      />
    </div>
  );
};
