import { useLayoutEffect, useRef, useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import ChargingIcon from '@icons/charging_16.svg';
import AvailableIcon from '@icons/check_16.svg';
import OccupiedIcon from '@icons/occupied_16.svg';
import OfflineIcon from '@icons/offline_16.svg';
import ErrorIcon from '@icons/red_error_16.svg';
import SuspendedIcon from '@icons/suspended_16.svg';
import { dateTimeFormat, formatDate } from '@utils/date-utils';

import { StatusIndicator, Tooltip } from '@destination/components';

import { EvseStatus, EvseStatusEnum } from '@omnis-pulse-types';

interface IEvseStatusOverview {
  status: EvseStatus;
}

const statusIconMap = {
  [EvseStatusEnum.AVAILABLE]: (
    <AvailableIcon data-testid="check-icon" className="text-[#0CA919]" />
  ),
  [EvseStatusEnum.CHARGING]: <ChargingIcon />,
  [EvseStatusEnum.ERROR]: <ErrorIcon />,
  [EvseStatusEnum.UNKNOWN]: <OfflineIcon />,
  [EvseStatusEnum.SUSPENDED]: <SuspendedIcon />,
  [EvseStatusEnum.OCCUPIED]: <OccupiedIcon />
};

const statusTextMap = {
  [EvseStatusEnum.AVAILABLE]: 'available',
  [EvseStatusEnum.CHARGING]: 'charging',
  [EvseStatusEnum.ERROR]: 'error_message',
  [EvseStatusEnum.SUSPENDED]: 'suspended',
  [EvseStatusEnum.UNKNOWN]: 'unknown',
  [EvseStatusEnum.OCCUPIED]: 'occupied'
};

export const EvseStatusOverview = ({ status }: IEvseStatusOverview) => {
  const { t } = usePortalTranslation();

  const StatusIcon = statusIconMap[status.status] || <OfflineIcon />;
  const statusText = statusTextMap[status.status] || 'unknown';

  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useLayoutEffect(() => {
    if (containerRef.current && textRef.current) {
      const containerWidth = containerRef.current.getBoundingClientRect().width;
      const textWidth = textRef.current.getBoundingClientRect().width;
      setIsTruncated(textWidth > containerWidth);
    }
  }, []);

  let statusMessage = t('states.' + statusText, { titleize: true });
  if (status.status === EvseStatusEnum.ERROR) {
    statusMessage = t('labels.entity_in_error', {
      entity: t('literals.evse').toUpperCase()
    });
  }

  const displayMessage = statusMessage;

  const tooltipMessage = `${isTruncated ? displayMessage : ''} ${t('literals.last_update', { titleize: true })} ${formatDate(status.timestamp, dateTimeFormat)}`;

  return (
    <Tooltip message={tooltipMessage}>
      <StatusIndicator icon={StatusIcon} label={displayMessage} />
    </Tooltip>
  );
};
