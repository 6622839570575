import { SITE_LOCAL_MODBUS_INTERFACE_PATH } from '@api/paths';
import { modifier } from '@utils/swr-utils';

export default async function setSiteLocalInterface(
  siteId: string,
  isEnabled: boolean
) {
  const url = SITE_LOCAL_MODBUS_INTERFACE_PATH(siteId);
  return await modifier(url, 'PATCH', { isEnabled });
}
