/* eslint-disable react-compiler/react-compiler */

import { useEffect, useMemo, useRef } from 'react';

import useSWR from 'swr';

import apiClient from '@api/apiClient';
import { CHARGER_EVSE_AGGREGATED_TELEMETRY_PATH } from '@api/paths';
import { filterDataByDateRange, isRangeWithinRange } from '@utils/date-utils';

import { EvseAggregatedTelemetry } from '@omnis-pulse-types';

interface Cache {
  data: EvseAggregatedTelemetry[] | null;
  from: string | null;
  to: string | null;
  timestamp: number | null;
}
const CACHE_EXPIRATION_MS = 5 * 60 * 1000;

const createCachingFetcher = () => {
  const cache: Cache = {
    data: null,
    from: null,
    to: null,
    timestamp: null
  };

  const invalidateCache = () => {
    cache.data = null;
    cache.from = null;
    cache.to = null;
    cache.timestamp = null;
  };

  const isCacheValid = (): boolean => {
    return (
      cache.timestamp !== null &&
      Date.now() - cache.timestamp < CACHE_EXPIRATION_MS &&
      cache.data !== null
    );
  };

  return {
    fetch: async (
      url: string,
      from: string,
      to: string
    ): Promise<EvseAggregatedTelemetry[]> => {
      if (!isCacheValid()) {
        const { data: newData } = await apiClient.get(url);

        cache.data = newData;
        cache.from = from;
        cache.to = to;
        cache.timestamp = Date.now();

        return newData;
      }

      if (
        cache.data &&
        cache.from &&
        cache.to &&
        isRangeWithinRange(
          { start: from, end: to },
          { start: cache.from, end: cache.to }
        )
      ) {
        return cache.data.map(evse => ({
          ...evse,
          telemetry: filterDataByDateRange(evse.telemetry, from, to)
        }));
      }

      const { data: newData } = await apiClient.get(url);

      cache.data = newData;
      cache.from = from;
      cache.to = to;
      cache.timestamp = Date.now();

      return newData;
    },
    invalidate: invalidateCache
  };
};

const cachingService = createCachingFetcher();

interface UseChargerEvseAggregatedTelemetryResult {
  data: EvseAggregatedTelemetry[] | undefined;
  isLoading: boolean;
  isError: boolean;
  mutate: () => Promise<EvseAggregatedTelemetry[] | undefined>;
  invalidateCache: () => void;
}

const useChargerEvseAggregatedTelemetry = (
  chargerId: string,
  from: string,
  to: string
): UseChargerEvseAggregatedTelemetryResult => {
  const dataRef = useRef<EvseAggregatedTelemetry[]>();

  const url = useMemo(
    () =>
      `${CHARGER_EVSE_AGGREGATED_TELEMETRY_PATH(chargerId)}?from=${from}&to=${to}`,
    [chargerId, from, to]
  );

  const { data, error, isLoading, mutate } = useSWR<EvseAggregatedTelemetry[]>(
    [url, from, to],
    ([fetchUrl, fetchFrom, fetchTo]: [string, string, string]) =>
      cachingService.fetch(fetchUrl, fetchFrom, fetchTo)
  );

  if (data) {
    dataRef.current = data;
  }

  useEffect(() => {
    return () => {
      cachingService.invalidate();
    };
  }, [chargerId]);

  return {
    data: dataRef.current || data,
    isLoading,
    isError: Boolean(error),
    mutate,
    invalidateCache: cachingService.invalidate
  };
};

export default useChargerEvseAggregatedTelemetry;
