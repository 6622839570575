import { useEffect, useMemo, useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { getTime } from 'date-fns';

import useChargerEvseAggregatedTelemetry from '@api/chargers/use-charger-evse-aggregated-telemetry';
import { VisibilityState } from '@components/sites/site/insights/charger-level/ChargerCurrentDelivered';
import { ChargerCurrentDeliveredLegend } from '@components/sites/site/insights/charger-level/ChargerCurrentDeliveredLegend';
import { EvsePhaseChart } from '@components/sites/site/insights/charger-level/evse-phase-graph/EvsePhaseChart';
import { UNIT_KILOWATTS, useInsightsContext } from '@context/InsightsContext';

import { Drawer, LoadingSkeleton, Typography } from '@destination/components';

import { ChargerActiveSession, EvseStatus } from '@omnis-pulse-types';

import { EvseSessionDetails } from './EvseSessionDetails';

export interface IEvseSessionDrawer {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  chargerId: string;
  evse: EvseStatus;
  session: ChargerActiveSession;
}

export const EvseSessionDrawer = ({
  isOpen,
  title,
  onClose,
  chargerId,
  evse,
  session
}: IEvseSessionDrawer) => {
  const { t } = usePortalTranslation('chargers');
  const { range, setActiveUnit } = useInsightsContext();

  useEffect(() => {
    setActiveUnit(UNIT_KILOWATTS);
  }, [setActiveUnit]);

  const { data, isLoading } = useChargerEvseAggregatedTelemetry(
    chargerId,
    range[0].toISOString(),
    range[1].toISOString()
  );

  const phasePowerData = data?.find(
    telemetry => telemetry.id === evse.id.toString()
  )?.telemetry;

  const [visibleLines, setVisibleLines] = useState<VisibilityState>({
    l1: false,
    l2: false,
    l3: false,
    soc: true,
    power: true,
    chargingBudget: true
  });

  const mapped = useMemo(() => {
    return (phasePowerData || []).map(d => ({
      ...d,
      timestamp:
        typeof d.timestamp === 'string'
          ? getTime(new Date(d.timestamp))
          : d.timestamp,
      numericTimestamp:
        typeof d.timestamp === 'string'
          ? getTime(new Date(d.timestamp))
          : undefined
    }));
  }, [phasePowerData]);

  return (
    <div onClick={event => event.stopPropagation()}>
      <Drawer title={title} isOpen={isOpen} onClose={onClose}>
        <div className="mt-4">
          {isLoading && <LoadingSkeleton height={140} />}
          {phasePowerData && (
            <>
              <div className="mb-4">
                <Typography variant="smallTitle">
                  {t('literals.energy_delivered', { titleize: true })}
                </Typography>
              </div>
              <EvsePhaseChart
                data={mapped}
                height={'140px'}
                isLoading={isLoading}
                visibleLines={visibleLines}
              />
              <div className="mb-8 flex-initial">
                <ChargerCurrentDeliveredLegend
                  visibleLines={visibleLines}
                  setVisibleLines={setVisibleLines}
                  activeMetric={UNIT_KILOWATTS}
                />
              </div>
            </>
          )}
          <EvseSessionDetails evse={evse} session={session} />
        </div>
      </Drawer>
    </div>
  );
};
