import { StatusIndicator, Tooltip, Typography } from '@destination/components';

export interface IDeviceStatus {
  label: string;
  value: string;
  icon: React.ReactNode;
  className?: string;
  tooltip?: string | React.ReactNode;
  'data-testid'?: string;
}

export const DeviceStatus = ({
  label,
  value,
  icon,
  className,
  tooltip,
  'data-testid': testId
}: IDeviceStatus) => {
  return (
    <Tooltip message={tooltip} data-testid={`${testId}-tooltip`}>
      <div className={`w-[141px] ${className ?? ''}`} data-testid={testId}>
        <Typography
          className="mb-1 whitespace-nowrap"
          data-testid={`${testId}-label`}
        >
          {label}
        </Typography>
        <StatusIndicator icon={icon} label={value} isBold />
      </div>
    </Tooltip>
  );
};
