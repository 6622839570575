import {
  rand,
  randCountryCode,
  randEmail,
  randFullName,
  randNumber,
  randPastDate,
  randUuid
} from '@ngneat/falso';

import { enumValueFaker, makeArray } from '@utils/faker-utils';

import {
  ApplicationRole,
  OrganizationDetails,
  UserDetails,
  UserOrganizationDetails,
  UserState
} from '@omnis-pulse-types';

export class OrganizationMockFactory {
  static createUserOrganizations(
    amount: number,
    data?: Partial<UserOrganizationDetails>
  ): UserOrganizationDetails[] {
    return makeArray(amount, () => this.createUserOrganization(data));
  }

  static createUserOrganization(
    data?: Partial<UserOrganizationDetails>
  ): UserOrganizationDetails {
    return {
      organizationOmnisId: randUuid(),
      name: randFullName(),
      countryIso: randCountryCode(),
      createdAt: randPastDate().toLocaleString(),
      roles: [
        rand([
          ApplicationRole.SERVICE_MANAGER,
          ApplicationRole.SERVICE_ENGINEER,
          ApplicationRole.READ_ONLY
        ])
      ],
      ...data
    };
  }

  static createOrganization(
    data?: Partial<OrganizationDetails>
  ): OrganizationDetails {
    return {
      organizationId: randUuid(),
      name: randFullName(),
      countryIso: randCountryCode(),
      createdAt: randPastDate().toLocaleString(),
      ...data
    };
  }

  static createOrganizationUser(data?: Partial<UserDetails>): UserDetails {
    return {
      id: randUuid(),
      displayName: randFullName(),
      email: randEmail(),
      state: enumValueFaker(UserState),
      applicationRoles: makeArray(randNumber({ min: 1, max: 2 }), () =>
        enumValueFaker(ApplicationRole)
      ),
      ...data
    };
  }

  static createOrganizationUsers(
    numberOfUsers = 3,
    data?: Partial<UserDetails>
  ): UserDetails[] {
    return makeArray(numberOfUsers, () => this.createOrganizationUser(data));
  }
}
