import { usePortalTranslation } from '@hooks/use-portal-translation';

import { AutomaticSaveRadioGroup } from '@destination/components';

import {
  PeakCapacityTimePeriod,
  UpdateSitePeakCapacity
} from '@omnis-pulse-types';

export interface IPeakCapacityTimeResolution {
  isDisabled: boolean;
  timePeriod: PeakCapacityTimePeriod;
  onSave: (payload: UpdateSitePeakCapacity) => Promise<void>;
}

export const PeakCapacityTimeResolution = ({
  isDisabled,
  timePeriod,
  onSave
}: IPeakCapacityTimeResolution) => {
  const { t, t_generic } = usePortalTranslation('siteSettings');
  const { t: t_errors } = usePortalTranslation('errors');

  const handleSave = async (timePeriod: string | number) => {
    await onSave({ timePeriod: timePeriod as PeakCapacityTimePeriod });
  };

  const label = t('literals.time_interval', { titleize: true });
  const errorToastMessage = t_errors('failed_to_save_settings', {
    entity: t_generic('literals.peak_capacity')
  });
  const options = [
    {
      value: PeakCapacityTimePeriod.PT15M,
      label: `15 ${t_generic('literals.minute_other')}`
    },
    {
      value: PeakCapacityTimePeriod.PT1H,
      label: `1 ${t_generic('literals.hour')}`
    },
    {
      value: PeakCapacityTimePeriod.PT1D,
      label: `1 ${t_generic('literals.day')}`
    }
  ];

  return (
    <AutomaticSaveRadioGroup
      disabled={isDisabled}
      initialValue={timePeriod}
      label={label}
      options={options}
      errorToastMessage={errorToastMessage}
      onSave={handleSave}
    />
  );
};
