import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import {
  ProcessedTelemetryPoint,
  useSiteInsightsData
} from '@hooks/use-site-insights-data';
import { isValid, subHours } from 'date-fns';
import { useParams } from 'react-router-dom';

export const UNIT_AMPERE = 'Ampere';
export const UNIT_KILOWATTS = 'Kilowatts';

type TimeRange = [Date, Date] | [undefined, undefined];

const isValidDate = (date: Date | undefined): boolean => {
  return !!date && isValid(date);
};

const createSafeDate = (timestamp: number): Date => {
  const date = new Date(timestamp);
  if (isValidDate(date)) {
    return date;
  }

  return new Date();
};

const getValidDateRange = (range: [Date, Date]): [string, string] => {
  const [start, end] = range;

  if (isValidDate(start) && isValidDate(end)) {
    return [start.toISOString(), end.toISOString()];
  }

  const now = new Date();
  const fourHoursAgo = subHours(now, 4);
  return [fourHoursAgo.toISOString(), now.toISOString()];
};

interface TimeRangeState {
  date: Date;
  timeRange: [Date, Date] | [undefined, undefined];
  timeRangePickerKey: number;
  range: [Date, Date];
}
interface TimeRangeActions {
  setDate: Dispatch<SetStateAction<Date>>;
  setTimeRange: Dispatch<SetStateAction<TimeRange>>;
  setTimeRangePickerKey: Dispatch<SetStateAction<number>>;
}
interface ConfigurationState {
  activeUnit: string;
  activeLoadManagementMode: string | undefined;
  isPeakCapacityEnabled: boolean;
  peakCapacityLimit: number | null;
  isLocalModbusEnabled: boolean;
}
interface ConfigurationActions {
  setActiveUnit: Dispatch<SetStateAction<string>>;
  setActiveLoadManagementMode: Dispatch<SetStateAction<string | undefined>>;
  setIsPeakCapacityEnabled: Dispatch<SetStateAction<boolean>>;
  setPeakCapacityLimit: Dispatch<SetStateAction<number | null>>;
  setIsLocalModbusEnabled: Dispatch<SetStateAction<boolean>>;
}
interface ZoomActions {
  handleZoom: (refAreaLeft: number, refAreaRight: number) => void;
  handleResetZoom: () => void;
}
interface TelemetryData {
  aggregatedTelemetry: ProcessedTelemetryPoint[];
  isLoading: boolean;
  isLoading_all: boolean;
}
export interface InsightsContextType
  extends TimeRangeState,
    TimeRangeActions,
    ConfigurationState,
    ConfigurationActions,
    ZoomActions,
    TelemetryData {}

const InsightsContext = createContext<InsightsContextType | null>(null);

interface InsightsProviderProps {
  children: ReactNode;
  initialTimeRange?: TimeRange;
  initialUnit?: string;
  initialLoadManagementMode?: string;
  initialIsPeakCapacityEnabled?: boolean;
  initialPeakCapacityLimit?: number | null;
  initialIsLocalModbusEnabled?: boolean;
}

export const InsightsProvider = ({
  children,
  initialTimeRange,
  initialUnit = UNIT_AMPERE,
  initialLoadManagementMode,
  initialIsPeakCapacityEnabled = false,
  initialPeakCapacityLimit = null,
  initialIsLocalModbusEnabled = false
}: InsightsProviderProps) => {
  const { id } = useParams<{ id: string }>();

  const [date, setDate] = useState(new Date());

  const now = new Date();
  const defaultRange: TimeRange = [subHours(now, 4), now];

  const [timeRange, setTimeRange] = useState<TimeRange>(
    initialTimeRange || defaultRange
  );

  const [timeRangePickerKey, setTimeRangePickerKey] = useState(0);
  const [activeUnit, setActiveUnit] = useState(initialUnit);
  const [activeLoadManagementMode, setActiveLoadManagementMode] = useState<
    string | undefined
  >(initialLoadManagementMode);
  const [isPeakCapacityEnabled, setIsPeakCapacityEnabled] = useState(
    initialIsPeakCapacityEnabled
  );
  const [peakCapacityLimit, setPeakCapacityLimit] = useState<number | null>(
    initialPeakCapacityLimit
  );
  const [isLocalModbusEnabled, setIsLocalModbusEnabled] = useState(
    initialIsLocalModbusEnabled
  );

  const range: [Date, Date] = useMemo(() => {
    try {
      const now = new Date();

      if (
        timeRange[0] === undefined ||
        timeRange[1] === undefined ||
        !isValidDate(timeRange[0]) ||
        !isValidDate(timeRange[1])
      ) {
        return [subHours(now, 4), now]; // Default range is the last 4 hours
      }

      const result = [new Date(timeRange[0]), new Date(timeRange[1])] as [
        Date,
        Date
      ];

      if (!isValidDate(result[0]) || !isValidDate(result[1])) {
        return [subHours(now, 4), now];
      }

      return result;
    } catch {
      const now = new Date();
      return [subHours(now, 4), now];
    }
  }, [timeRange]);

  const validRange = useMemo(() => getValidDateRange(range), [range]);

  const {
    aggregatedTelemetry,
    isLoading,
    isLoading_all,
    refetchAndInvalidateData
  } = useSiteInsightsData({
    siteId: id ?? '',
    startTimeISO: validRange[0],
    endTimeISO: validRange[1],
    setActiveLoadManagementMode,
    setIsPeakCapacityEnabled,
    setPeakCapacityLimit,
    setIsLocalModbusEnabled
  });

  const handleZoom = useCallback(
    (refAreaLeft: number, refAreaRight: number) => {
      const [left, right] =
        refAreaLeft < refAreaRight
          ? [refAreaLeft, refAreaRight]
          : [refAreaRight, refAreaLeft];

      const startDate = createSafeDate(left);
      const endDate = createSafeDate(right);

      setTimeRange([startDate, endDate]);
      setTimeRangePickerKey(previousValue => previousValue + 1);

      refetchAndInvalidateData();
    },
    [refetchAndInvalidateData, setTimeRange, setTimeRangePickerKey]
  );

  const handleResetZoom = useCallback(() => {
    const now = new Date();
    const fourHoursAgo = subHours(now, 4);

    if (isValidDate(now) && isValidDate(fourHoursAgo)) {
      setTimeRange([fourHoursAgo, now]);
      setTimeRangePickerKey(prev => prev + 2);

      refetchAndInvalidateData();
    }
  }, [refetchAndInvalidateData, setTimeRange, setTimeRangePickerKey]);

  const timeRangeState = useMemo(
    () => ({
      date,
      timeRange,
      timeRangePickerKey,
      range
    }),
    [date, timeRange, timeRangePickerKey, range]
  );

  const timeRangeActions = useMemo(
    () => ({
      setDate,
      setTimeRange,
      setTimeRangePickerKey
    }),
    []
  );

  const configState = useMemo(
    () => ({
      activeUnit,
      activeLoadManagementMode,
      isPeakCapacityEnabled,
      peakCapacityLimit,
      isLocalModbusEnabled
    }),
    [
      activeUnit,
      activeLoadManagementMode,
      isPeakCapacityEnabled,
      peakCapacityLimit,
      isLocalModbusEnabled
    ]
  );

  const configActions = useMemo(
    () => ({
      setActiveUnit,
      setActiveLoadManagementMode,
      setIsPeakCapacityEnabled,
      setPeakCapacityLimit,
      setIsLocalModbusEnabled
    }),
    []
  );

  const zoomActions = useMemo(
    () => ({
      handleZoom,
      handleResetZoom
    }),
    [handleZoom, handleResetZoom]
  );

  const telemetryData = useMemo(
    () => ({
      aggregatedTelemetry,
      isLoading,
      isLoading_all
    }),
    [aggregatedTelemetry, isLoading, isLoading_all]
  );

  const contextValue = useMemo(
    () => ({
      ...timeRangeState,
      ...timeRangeActions,
      ...configState,
      ...configActions,
      ...zoomActions,
      ...telemetryData
    }),
    [
      timeRangeState,
      timeRangeActions,
      configState,
      configActions,
      zoomActions,
      telemetryData
    ]
  );

  return (
    <InsightsContext.Provider value={contextValue}>
      {children}
    </InsightsContext.Provider>
  );
};

export const useInsightsContext = (startTime?: Date, endTime?: Date) => {
  const context = useContext(InsightsContext);

  if (!context) {
    throw new Error(
      'useInsightsContext must be used within a InsightsProvider'
    );
  }

  const { setTimeRange } = context;

  useEffect(() => {
    if (
      startTime &&
      endTime &&
      isValidDate(startTime) &&
      isValidDate(endTime)
    ) {
      setTimeRange([startTime, endTime]);
    }
  }, [startTime, endTime, setTimeRange]);

  return context;
};
