import useSWR from 'swr';

import { CHARGERS_BY_SITE_ID_PATH } from '@api/paths';
import { fetcher } from '@utils/swr-utils';

import { ChargerDetails } from '@omnis-pulse-types';

export default function useChargerDetails(
  siteId?: string,
  chargerId?: string,
  options?: {
    revalidateIfStale?: boolean;
  }
) {
  const url =
    siteId && chargerId
      ? CHARGERS_BY_SITE_ID_PATH(siteId) + `/${chargerId}`
      : null;

  const { data, error, isLoading, mutate } = useSWR<ChargerDetails>(
    url,
    fetcher,
    options
  );
  return { charger: data, isLoading, isError: Boolean(error), mutate };
}
