import { CHARGER_BY_ID_PATH } from '@api/paths';
import { modifier } from '@utils/swr-utils';

import { UpdateChargerNameCommand } from '@omnis-pulse-types';

export default function updateChargerName(
  chargerId: string,
  payload: UpdateChargerNameCommand
) {
  const url = CHARGER_BY_ID_PATH(chargerId);
  return modifier(url, 'PATCH', payload);
}
