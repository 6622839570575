import { useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';

import { CHARGER_BY_SITE_ID_PATH } from '@api/paths';
import updateChargerName from '@api/sites/chargers/update-charger';

import { Dialog, useAlert, useInput } from '@destination/components';

import { EditChargerNameForm } from './EditChargerNameForm';

export interface IEditChargerNameDialog {
  showDialog: boolean;
  name: string;
  onClose: () => void;
}

export const EditChargerNameDialog = ({
  showDialog,
  name,
  onClose
}: IEditChargerNameDialog) => {
  const { t } = usePortalTranslation();
  const { t: t_errors } = usePortalTranslation('errors');
  const { notifySuccess, notifyError } = useAlert();
  const { id, chargerId } = useParams() as { id: string; chargerId: string };

  const [isUpdating, setIsUpdating] = useState(false);
  const {
    value: updatedName,
    valueChangeHandler: handleNameChange,
    reset: resetName
  } = useInput(() => true, name);

  const handleClose = () => {
    resetName();
    onClose();
  };

  const handleConfirm = async () => {
    setIsUpdating(true);
    const { error } = await updateChargerName(chargerId, {
      name: updatedName
    });

    if (error) {
      notifyError({
        header: t_errors('failed_to_save_settings', {
          entity: t('literals.charger')
        })
      });
    } else {
      await mutate(CHARGER_BY_SITE_ID_PATH(id, chargerId));
      notifySuccess({
        header: t('labels.save_settings_success', {
          entity: t('literals.charger', { titleize: true })
        })
      });
      onClose();
    }
    setIsUpdating(false);
  };

  const header = t('buttons.edit', {
    entity: t('literals.charger'),
    titleize: true
  });
  const cancelButton = t('buttons.cancel', { titleize: true });
  const confirmButton = t('buttons.save', { titleize: true });

  return (
    <Dialog
      header={header}
      isOpen={showDialog}
      content={
        <EditChargerNameForm
          name={updatedName}
          onNameChange={handleNameChange}
        />
      }
      cancelButton={cancelButton}
      confirmButton={confirmButton}
      isConfirmDisabled={name === updatedName}
      onCancel={handleClose}
      onClose={handleClose}
      onConfirm={handleConfirm}
      isLoading={isUpdating}
    />
  );
};
