import { Typography } from '@destination/components';

export interface ILegendItem {
  isToggled?: boolean;
  color?: string;
  label: string;
  style?: object;
  onClick?: () => void;
  type?: 'area' | 'line';
}

export const LegendItem = ({
  isToggled = true,
  color,
  label,
  style,
  onClick,
  type = 'area'
}: ILegendItem) => {
  const containerClasses = ['group flex items-center gap-1'];
  const labelClasses = [];

  if (!isToggled) {
    labelClasses.push('text-gray-400');
  }

  if (onClick) {
    labelClasses.push('group-hover:text-gray-600');
  }

  const legendItemStyle = {
    ...style,
    backgroundColor: type === 'area' && isToggled ? color : undefined,
    borderColor: isToggled ? color : '#9CA3AF' // Gray-400 when toggled off
  };

  const legendColorClasses =
    type === 'area'
      ? ['h-[5px] w-2.5 rounded-[1px]']
      : ['h-[1px] w-4 border-t-[1.5px]'];

  if (!isToggled) {
    if (type === 'area') {
      legendColorClasses.push('bg-gray-300');
    } else {
      legendColorClasses.push('border-gray-300');
    }
  }

  const content = (
    <>
      <div>
        <div
          data-testid="legend-color"
          className={legendColorClasses.join(' ')}
          style={legendItemStyle}
        ></div>
      </div>
      <Typography className={labelClasses.join(' ')} variant="smallText">
        {label}
      </Typography>
    </>
  );

  return onClick ? (
    <button className={containerClasses.join(' ')} onClick={onClick}>
      {content}
    </button>
  ) : (
    <div className={containerClasses.join(' ')}>{content}</div>
  );
};
