interface DottedLegendItemProps {
  color: string;
  label: string;
}

export const DottedLegendItem = ({ color, label }: DottedLegendItemProps) => {
  return (
    <div data-testid="dotted-legend-wrapper" className="flex items-center">
      <svg width="24" height="16" viewBox="0 0 24 16" className="mr-2">
        <line
          data-testid="dotted-line"
          x1="3"
          y1="8"
          x2="9"
          y2="8"
          stroke={color}
          strokeWidth="2"
        />
        <line
          data-testid="dotted-line"
          x1="15"
          y1="8"
          x2="21"
          y2="8"
          stroke={color}
          strokeWidth="2"
        />
      </svg>
      <span className="text-sm text-gray-700">{label}</span>
    </div>
  );
};
