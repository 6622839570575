import { useParams } from 'react-router-dom';

import setSiteLocalInterface from '@api/sites/set-site-local-interface';
import updateSiteLocalInterfaceOptions from '@api/sites/update-site-local-interface-options';
import useSiteLocalModbusInterface from '@api/sites/use-site-local-modbus-interface';
import { useReadOnly } from '@context/ReadOnlyContext';

import { Card, DataError, LoadingSkeleton } from '@destination/components';

import { LocalInterfaceSwitch } from './LocalInterfaceSwitch';
import {
  LocalModbusInterfaceOptions,
  ModbusInterfaceOption
} from './LocalModbusInterfaceOptions';

export const LocalModbusInterface = () => {
  const { id } = useParams();
  const { data, isLoading, isError, isNotFound, mutate } =
    useSiteLocalModbusInterface(id);
  const { isReadOnly } = useReadOnly();

  const handleSave = async (isEnabled: boolean) => {
    if (id) {
      const { error } = await setSiteLocalInterface(id, isEnabled);
      if (error) throw new Error();
      await mutate();
    }
  };

  const toggleOption = async (option: ModbusInterfaceOption) => {
    if (!id || !data) return;

    const optionKey =
      option === ModbusInterfaceOption.ExternalController
        ? 'isExternalControllerEnabled'
        : 'isDsoLimitEnabled';

    const { error } = await updateSiteLocalInterfaceOptions(id, {
      [optionKey]: !data[optionKey]
    });

    if (error) throw new Error();
    await mutate();
  };

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSkeleton height={20} />;
    }

    if (isError) {
      return <DataError onRefresh={() => mutate(undefined, true)} />;
    }

    if (data === undefined) {
      return null;
    }

    return (
      <div>
        <div className="flex items-center px-3 py-4">
          <LocalInterfaceSwitch
            isDisabled={isReadOnly}
            checked={data?.isEnabled ?? false}
            onSave={handleSave}
          />
        </div>
        {data?.isEnabled && (
          <LocalModbusInterfaceOptions
            isDisabled={isReadOnly}
            data={data}
            toggleOption={toggleOption}
          />
        )}
      </div>
    );
  };

  if (isNotFound) {
    return null;
  }

  return (
    <Card data-testid="local-modbus-interface-card" className="w-[620px]">
      {renderContent()}
    </Card>
  );
};
