import { Dispatch, SetStateAction } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import { LegendItem } from '@components/charts/LegendItem';
import { UNIT_AMPERE } from '@context/InsightsContext';

import { DataKey, VisibilityState } from './ChargerCurrentDelivered';

interface IChargerCurrentDeliveredLegend {
  visibleLines: VisibilityState;
  setVisibleLines: Dispatch<SetStateAction<VisibilityState>>;
  activeMetric?: string;
}

export const ChargerCurrentDeliveredLegend = ({
  visibleLines,
  setVisibleLines,
  activeMetric = UNIT_AMPERE
}: IChargerCurrentDeliveredLegend) => {
  const { t, t_generic } = usePortalTranslation('chargers');
  const isAmpere = activeMetric === UNIT_AMPERE;
  const metricType = isAmpere
    ? 'literals.charging_current'
    : 'literals.charging_power';

  const toggleLineVisibility = (line: DataKey) => {
    setVisibleLines(prev => ({
      ...prev,
      [line]: !prev[line]
    }));
  };

  const legendColors = {
    l1: '#000000',
    l2: '#77A3FC',
    l3: '#FF9F8C',
    soc: '#E64997',
    chargingBudget: '#0066CC',
    power: '#000000'
  };

  return (
    <div className="mt-2 flex flex-row items-center gap-8 pl-16">
      <LegendItem
        key="chargingBudget"
        isToggled={visibleLines.chargingBudget !== false}
        onClick={() => toggleLineVisibility('chargingBudget' as DataKey)}
        color={legendColors.chargingBudget}
        label={t('literals.charging_budget', { titleize: true })}
        type="line"
      />

      {isAmpere ? (
        <>
          <LegendItem
            key="l1"
            isToggled={visibleLines.l1 !== false}
            onClick={() => toggleLineVisibility('l1' as DataKey)}
            color={legendColors.l1}
            label={`${t(metricType, { titleize: true })} - ${t_generic('literals.phase 1')}`}
            type="line"
          />

          <LegendItem
            key="l2"
            isToggled={visibleLines.l2 !== false}
            onClick={() => toggleLineVisibility('l2' as DataKey)}
            color={legendColors.l2}
            label={`${t(metricType, { titleize: true })} - ${t_generic('literals.phase 2')}`}
            type="line"
          />

          <LegendItem
            key="l3"
            isToggled={visibleLines.l3 !== false}
            onClick={() => toggleLineVisibility('l3' as DataKey)}
            color={legendColors.l3}
            label={`${t(metricType, { titleize: true })} - ${t_generic('literals.phase 3')}`}
            type="line"
          />
        </>
      ) : (
        <LegendItem
          key="power"
          isToggled={visibleLines.power !== false}
          onClick={() => toggleLineVisibility('power' as DataKey)}
          color={legendColors.power}
          label={t(metricType, { titleize: true })}
          type="line"
        />
      )}

      <LegendItem
        key="soc"
        isToggled={visibleLines.soc !== false}
        onClick={() => toggleLineVisibility('soc' as DataKey)}
        color={legendColors.soc}
        label={t('literals.state_of_charge', { titleize: true })}
        type="line"
      />
    </div>
  );
};
