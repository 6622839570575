import { usePortalTranslation } from '@hooks/use-portal-translation';
import { NavLink } from 'react-router-dom';

import useGateway from '@api/gateways/use-gateway';
import { useReadOnly } from '@context/ReadOnlyContext';
import { useSiteDevicesContext } from '@context/SiteDevicesContext';
import GridMeterIcon from '@icons/grid_meter_96.svg';
import PlusIcon from '@icons/plus_16.svg';

import {
  Button,
  LoadingSkeleton,
  NothingConfigured,
  Tooltip,
  Typography
} from '@destination/components';

import { ConnectionStatusEnum } from '@omnis-pulse-types';

export const GridMeterNotConfigured = () => {
  const { t } = usePortalTranslation();

  const { deviceData } = useSiteDevicesContext();
  const { isReadOnly } = useReadOnly();

  const { gateway, isLoading, isError } = useGateway(deviceData.gateways[0].id);

  const isGatewayOnline =
    gateway?.connectionStatus.find(status => status.type === 'ABB')?.status ===
    ConnectionStatusEnum.CONNECTED;

  const title = t('labels.no_configured', { entity: t('literals.grid_meter') });
  const buttonLabel = t('buttons.add', {
    entity: t('literals.grid_meter'),
    titleize: true
  });

  const isButtonDisabled = isError || !isGatewayOnline;

  if (isLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <LoadingSkeleton className="rounded-[10px]" height={431} />
      </div>
    );
  }

  return (
    <div className="flex h-full items-center justify-center">
      <NothingConfigured title={title} icon={<GridMeterIcon />}>
        <NavLink to="add">
          <Tooltip
            message={
              isReadOnly ? null : (
                <TooltipMessage isGatewayOnline={isGatewayOnline} />
              )
            }
          >
            <Button
              variant="primary"
              icon={<PlusIcon />}
              disabled={isButtonDisabled || isReadOnly}
              data-testid="add-grid-meter-button"
            >
              {buttonLabel}
            </Button>
          </Tooltip>
        </NavLink>
      </NothingConfigured>
    </div>
  );
};

const TooltipMessage = ({ isGatewayOnline }: { isGatewayOnline: boolean }) => {
  const { t } = usePortalTranslation('gridMeters');

  const title = t('tooltips.tgp_offline.title');
  const description = t('tooltips.tgp_offline.description');

  if (!isGatewayOnline) {
    return (
      <div className="max-w-[267px]">
        <Typography variant="smallTitle">{title}</Typography>
        <Typography variant="description">{description}</Typography>
      </div>
    );
  }

  return null;
};
