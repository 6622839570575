import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams, useSearchParams } from 'react-router-dom';

import useChargerEventLog from '@api/chargers/use-charger-event-log';
import useChargerDetails from '@api/sites/chargers/use-charger-details';
import { TabLabel } from '@components/TabLabel';
import { LevelFilterButton } from '@components/table/logs/LevelFilterButton';
import { LogsContainer } from '@components/table/logs/LogsContainer';
import { LogsDateTimeFilters } from '@components/table/logs/LogsDateTimeFilters';

import { Card, LoadingSkeleton, Typography } from '@destination/components';

export const Logs = () => {
  const { id: siteId, chargerId } = useParams();
  const [searchParams] = useSearchParams();

  const { charger, isLoading: isLoadingCharger } = useChargerDetails(
    siteId,
    chargerId
  );
  const {
    logs,
    isLoading,
    isLoadingMore,
    isError,
    hasReachedEnd,
    handleLoadMore
  } = useChargerEventLog(chargerId, true, searchParams);

  const { t } = usePortalTranslation();
  const title = t('labels.entity_event_logs', { entity: charger?.name });

  return (
    <>
      {charger && <TabLabel label={title} />}
      <div className="mb-8">
        {isLoadingCharger ? (
          <LoadingSkeleton isDarker className="w-full max-w-sm" height={30.4} />
        ) : (
          <Typography variant="pageTitle">{title}</Typography>
        )}
      </div>
      <div className="mb-3 flex gap-3">
        <LogsDateTimeFilters />
        <LevelFilterButton />
      </div>
      <Card className="!shadow-none">
        <LogsContainer
          isError={isError}
          isLoadingInitial={isLoading}
          isLoadingMore={isLoadingMore}
          isCompleted={hasReachedEnd}
          logs={logs}
          onLoadMore={handleLoadMore}
        />
      </Card>
    </>
  );
};
