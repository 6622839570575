import { useMemo, useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';

import { CHARGER_BY_SITE_ID_PATH } from '@api/paths';
import updateChargerSafetyLimit from '@api/sites/chargers/update-charger-safety-limit';
import { greaterThanZero, isEmpty, isNotANumber } from '@utils/input-utils';

import { Dialog, useAlert, useInput } from '@destination/components';

import { EditChargerSafetyLimitForm } from './EditChargerSafetyLimitForm';

export interface IEditChargerSafetyLimitDialog {
  showDialog: boolean;
  safetyLimit?: string;
  onClose: () => void;
}

export const EditChargerSafetyLimitDialog = ({
  showDialog,
  safetyLimit,
  onClose
}: IEditChargerSafetyLimitDialog) => {
  const { id, chargerId } = useParams() as { id: string; chargerId: string };
  const [isUpdating, setIsUpdating] = useState(false);
  const {
    value: updatedSafetyLimit,
    valueChangeHandler: handleSafetyLimitChange,
    reset: resetSafetyLimit
  } = useInput(() => true, (safetyLimit ?? '').toString());
  const { notifySuccess, notifyError } = useAlert();

  const { t } = usePortalTranslation();
  const { t: t_errors } = usePortalTranslation('errors');

  const handleClose = () => {
    resetSafetyLimit();
    onClose();
  };

  const handleConfirm = async () => {
    setIsUpdating(true);

    const { error } = await updateChargerSafetyLimit(chargerId, {
      safetyLimit: Number(updatedSafetyLimit)
    });

    if (error) {
      notifyError({
        header: t_errors('failed_to_save_settings', {
          entity: t('literals.charger')
        })
      });
    } else {
      await mutate(CHARGER_BY_SITE_ID_PATH(id, chargerId));
      notifySuccess({
        header: t('labels.save_settings_success', {
          entity: t('literals.charger', { titleize: true })
        })
      });
      onClose();
    }
    setIsUpdating(false);
  };

  const helperText = useMemo(() => {
    if (isEmpty(updatedSafetyLimit)) {
      return t_errors('field_cannot_be_empty');
    }
    if (isNotANumber(updatedSafetyLimit)) {
      return t_errors('field_must_be_numeric');
    }
    if (!greaterThanZero(updatedSafetyLimit)) {
      return t_errors('field_must_be_positive_numeric');
    }
    return undefined;
  }, [t_errors, updatedSafetyLimit]);

  const isConfirmDisabled = useMemo(() => {
    if (helperText) {
      return true;
    }
    if (safetyLimit === updatedSafetyLimit) {
      return true;
    }
    return false;
  }, [helperText, safetyLimit, updatedSafetyLimit]);

  const header = t('buttons.edit', {
    entity: t('literals.charger'),
    titleize: true
  });
  const cancelButton = t('buttons.cancel', { titleize: true });
  const confirmButton = t('buttons.save', { titleize: true });

  return (
    <Dialog
      header={header}
      isOpen={showDialog}
      content={
        <EditChargerSafetyLimitForm
          safetyLimit={updatedSafetyLimit}
          helperText={helperText}
          onSafetyLimitChange={handleSafetyLimitChange}
        />
      }
      cancelButton={cancelButton}
      confirmButton={confirmButton}
      isConfirmDisabled={isConfirmDisabled}
      onCancel={handleClose}
      onClose={handleClose}
      onConfirm={handleConfirm}
      isLoading={isUpdating}
    />
  );
};
