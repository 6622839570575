import { useDateFilter } from '@hooks/use-date-filter';
import { toDate } from 'date-fns';
import { useSearchParams } from 'react-router-dom';

import { DateTimeRangePicker } from '@destination/components';

export const LogsDateTimeFilters = () => {
  const { handleDateRangeChange } = useDateFilter();
  const [searchParams] = useSearchParams();

  let initialRange: [Date, Date] | undefined = undefined;

  const from = searchParams.get('from');
  const to = searchParams.get('to');

  if (from && to) {
    const fromDateObject = toDate(from);
    const toDateObject = toDate(to);

    initialRange = [fromDateObject, toDateObject];
  }

  // The key is used to force React to render a fresh component
  const key = from ?? new Date().toISOString();

  return (
    <DateTimeRangePicker
      key={key}
      initialRange={initialRange}
      isFutureDisabled={true}
      onChange={handleDateRangeChange}
    />
  );
};
