import { useMemo } from 'react';

import { TOverloadedFunction } from '@hooks/use-portal-translation';
import { TooltipProps } from 'recharts';

import { CustomFloatingTooltip } from '@components/charts/CustomFloatingTooltip';
import { TooltipItem } from '@components/charts/TootltipItem';
import { useInsightsContext } from '@context/InsightsContext';

import { buildCommonTooltipItems } from '../site-insights-tooltip-utils';

interface EnergyDeliveredTooltipProps extends TooltipProps<number, string> {
  t: TOverloadedFunction;
}

export const EnergyDeliveredGraphTooltip = ({
  active,
  payload,
  label,
  t
}: EnergyDeliveredTooltipProps) => {
  const { isPeakCapacityEnabled, isLocalModbusEnabled } = useInsightsContext();

  const completePayload = useMemo<TooltipItem[]>(() => {
    if (!payload || payload.length === 0) {
      return [];
    }

    let bestDataPoint = null;
    let maxProps = 0;

    for (const item of payload) {
      if (item.payload && Object.keys(item.payload).length > maxProps) {
        bestDataPoint = item.payload;
        maxProps = Object.keys(item.payload).length;
      }
    }

    const data = bestDataPoint || payload[0]?.payload || {};

    // Check if we have valid data to display
    if (Object.keys(data).length === 0) {
      return [];
    }

    const chargersPower = data.chargers_power;
    const residualPower = data.residual_power;
    const gridLimitValue = data.grid_limit_power;
    const loadSheddingValue = data.load_shedding_power;
    const chargerBudgetTotal = data.charger_budget_power_total;
    const peakCapacityLimit = data.peak_capacity_limit;

    // Check if we have at least one valid value to display
    const hasGridLimit =
      gridLimitValue !== undefined && gridLimitValue !== null;
    const hasLoadShedding =
      isLocalModbusEnabled &&
      loadSheddingValue !== undefined &&
      loadSheddingValue !== null;
    const hasChargerBudget =
      chargerBudgetTotal !== undefined && chargerBudgetTotal !== null;
    const hasPeakCapacity =
      isPeakCapacityEnabled &&
      peakCapacityLimit !== undefined &&
      peakCapacityLimit !== null;

    // Only return empty if we have no data to show at all
    if (
      (chargersPower === undefined || chargersPower === 0) &&
      (residualPower === undefined || residualPower === 0) &&
      !hasGridLimit &&
      !hasLoadShedding &&
      !hasChargerBudget &&
      !hasPeakCapacity
    ) {
      return [];
    }

    // Prepare translated labels
    const residualName = t('labels.entity_load', {
      entity: t('literals.residual'),
      titleize: true
    });

    const chargerName = t('labels.entity_consumption', {
      entity: t('literals.charger_other'),
      titleize: true
    });

    const gridLimitName = t('literals.grid_limit', { titleize: true });

    const loadSheddingName = t('labels.entity_limit', {
      entity: t('literals.modbus'),
      titleize: true
    });

    const chargingBudgetName = t('labels.entity_limit', {
      entity: t('literals.charging_budget'),
      titleize: true
    });

    const peakCapacityName = t('labels.entity_limit', {
      entity: t('literals.peak_capacity'),
      titleize: true
    });

    return buildCommonTooltipItems({
      residualConfig: {
        key: 'residual_power',
        value: residualPower || 0,
        unit: 'kW',
        name: residualName
      },
      chargerConfig: {
        key: 'chargers_power',
        value: chargersPower || 0,
        unit: 'kW',
        name: chargerName
      },
      gridLimitConfig: {
        key: 'grid_limit_power',
        value: gridLimitValue,
        unit: 'kW',
        name: gridLimitName
      },
      conditionalConfigs: {
        loadShedding: {
          key: 'load_shedding_power',
          value: loadSheddingValue,
          unit: 'kW',
          isEnabled: isLocalModbusEnabled,
          name: loadSheddingName
        },
        chargerBudget: {
          key: 'charger_budget_power_total',
          value: chargerBudgetTotal,
          unit: 'kW',
          isEnabled: true,
          name: chargingBudgetName
        },
        peakCapacity: {
          key: 'peak_capacity_limit',
          value: peakCapacityLimit,
          unit: 'kW',
          isEnabled: isPeakCapacityEnabled,
          name: peakCapacityName
        }
      }
    });
  }, [payload, t, isPeakCapacityEnabled, isLocalModbusEnabled]);

  if (!active || completePayload.length === 0) {
    return null;
  }

  const tooltipKey = `tooltip-${label}`;

  return (
    <div key={tooltipKey} style={{ pointerEvents: 'none' }}>
      <CustomFloatingTooltip
        active={active}
        label={label}
        payload={completePayload}
      />
    </div>
  );
};
