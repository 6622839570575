import CCSIcon from '@icons/ccs_16.svg';
import ChademoIcon from '@icons/chademo_16.svg';
import ACIcon from '@icons/type2_16.svg';

import { SocketTypeEnum } from '@omnis-pulse-types';

export const getSocketTypeIcon = (
  socketType: SocketTypeEnum,
  color = '#000000'
) => {
  switch (socketType) {
    case SocketTypeEnum.CCS:
      return <CCSIcon data-testid="ccs-icon" style={{ color }} />;
    case SocketTypeEnum.CHADEMO:
      return <ChademoIcon data-testid="chademo-icon" style={{ color }} />;
    case SocketTypeEnum.AC:
      return <ACIcon data-testid="ac-icon" style={{ color }} />;
    default:
      return null;
  }
};

export const getSocketTypeLabel = (socketType: SocketTypeEnum) => {
  return socketType === SocketTypeEnum.UNUSED
    ? SocketTypeEnum.UNKNOWN
    : socketType;
};
