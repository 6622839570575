import { usePortalTranslation } from '@hooks/use-portal-translation';

import { Input } from '@destination/components';

export interface IEditChargerSafetyLimitForm {
  safetyLimit: string;
  helperText?: string;
  onSafetyLimitChange: (newSafetyLimit: string) => void;
}

export const EditChargerSafetyLimitForm = ({
  safetyLimit,
  helperText,
  onSafetyLimitChange
}: IEditChargerSafetyLimitForm) => {
  const { t } = usePortalTranslation('chargers');

  const label = t('literals.max_input_current', { titleize: true });

  return (
    <form className="mb-2 mt-[35px]">
      <Input
        data-testid="edit-charger-safety-limit-input"
        id="name"
        label={label}
        value={safetyLimit}
        helperText={helperText}
        error={Boolean(helperText)}
        onChange={event => onSafetyLimitChange(event.target.value)}
      />
    </form>
  );
};
