import { ReactNode } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { TooltipProps } from 'recharts';
import { ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { CustomFloatingTooltip } from '@components/charts/CustomFloatingTooltip';
import { UNIT_AMPERE, useInsightsContext } from '@context/InsightsContext';

export const EvsePhaseChartTooltip = (props: TooltipProps<number, string>) => {
  const { active, payload, label } = props;
  const { t, t_generic } = usePortalTranslation('chargers');
  const { activeUnit } = useInsightsContext();

  if (!active || !payload || payload.length === 0) {
    return null;
  }

  const tooltipFormatter = (
    value: number,
    name: string,
    props: {
      dataKey?: string | number;
      name?: string;
      color?: string;
      value?: ValueType;
      unit?: ReactNode;
      payload?: {
        [key: string]: string | number;
      };
    }
  ): [string, string] => {
    if (name === 'soc') {
      return [`${value}%`, t('literals.state_of_charge')];
    }

    return [`${value} ${props.unit || ''}`, props.name ?? name];
  };

  return (
    <CustomFloatingTooltip
      active={active}
      payload={payload}
      label={label}
      activeUnit={activeUnit}
      width="250px"
      timestampLabel={t_generic('literals.timestamp')}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      filterPayload={(item: any) => {
        const dataKey = String(item.dataKey);

        if (activeUnit === UNIT_AMPERE) {
          return dataKey.includes('chargers_current_l');
        } else {
          return dataKey.includes('chargers_power');
        }
      }}
      additionalFields={[
        {
          key: 'soc',
          label: t('literals.state_of_charge', { titleize: true }),
          stroke: '#E64997',
          unit: '%'
        },
        {
          key:
            activeUnit === UNIT_AMPERE
              ? 'charging_budget_limit_current'
              : 'charging_budget_limit_power',
          label: t_generic('literals.charging_budget', {
            titleize: true
          }),
          stroke: '#00ACEC',
          unit: (unit: string) => (unit === UNIT_AMPERE ? 'A' : 'kW')
        }
      ]}
      formatter={tooltipFormatter}
    />
  );
};
