import { useMemo } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';

import { Card } from '@destination/components';

import { ChargerDetails, SocketTypeEnum } from '@omnis-pulse-types';

import { ChargerNamePropertyRow } from './ChargerNamePropertyRow';
import { ChargerPropertyRow } from './ChargerPropertyRow';
import { ChargerSafetyLimitPropertyRow } from './ChargerSafetyLimitPropertyRow';
import { ChargerSocketTypePropertyRow } from './ChargerSocketTypePropertyRow';

export interface IChargerInformation {
  charger: ChargerDetails;
}

export const ChargerInformation = ({ charger }: IChargerInformation) => {
  const { t, t_generic } = usePortalTranslation('chargers');

  const socketTypes = useMemo(() => {
    const types: SocketTypeEnum[] = [];
    charger.evseStatuses.forEach(eveStatus => {
      eveStatus.sockets.forEach(socket => {
        types.push(socket.type);
      });
    });
    return types;
  }, [charger.evseStatuses]);

  const serialLabel = t_generic('literals.serial_number', { titleize: true });
  const productTypeLabel = t_generic('literals.product_type', {
    titleize: true
  });
  const maxPowerLabel = t('literals.max_power', { titleize: true });
  const numberOfEvsesLabel = t_generic('labels.number_of_entity', {
    entity: t_generic('literals.evse_other'),
    titleize: true
  });
  const firmwareLabel = t_generic('labels.entity_version', {
    entity: t_generic('literals.firmware'),
    titleize: true
  });

  return (
    <Card className="flex h-min w-full flex-col gap-4 !rounded-md">
      <ChargerNamePropertyRow name={charger.name} />
      <ChargerPropertyRow
        data-testid="charger-serial-number"
        label={serialLabel}
        value={charger.serialNumber}
      />
      <ChargerPropertyRow
        data-testid="charger-product-type"
        label={productTypeLabel}
        value={charger.productType}
      />
      <ChargerPropertyRow
        data-testid="charger-max-power"
        label={maxPowerLabel}
        value={`${charger.maxPower} kW`}
      />
      <ChargerSafetyLimitPropertyRow safetyLimit={charger.safetyLimit} />
      <ChargerSocketTypePropertyRow
        socketTypes={socketTypes}
        data-testid="charger-outlet-type"
      />
      <ChargerPropertyRow
        data-testid="charger-number-evses"
        label={numberOfEvsesLabel}
        value={`${charger.numberOfEvses}`}
      />
      {charger.firmwareVersion && (
        <ChargerPropertyRow
          data-testid="charger-firmware-version"
          label={firmwareLabel}
          value={charger.firmwareVersion}
        />
      )}
    </Card>
  );
};
