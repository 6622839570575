import { useEffect, useMemo, useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { getTime } from 'date-fns';

import useChargerEvseAggregatedTelemetry from '@api/chargers/use-charger-evse-aggregated-telemetry';
import { UNIT_AMPERE, useInsightsContext } from '@context/InsightsContext';

import { Tooltip, Typography } from '@destination/components';

import { ChargingTelemetryPoint } from '@omnis-pulse-types';

import { EvseStatusAxisTooltipContent } from '../site-level/evse-status-chart/EvseStatusAxisTooltipContent';
import { ChargerCurrentDeliveredLegend } from './ChargerCurrentDeliveredLegend';
import { EvsePhaseChart } from './evse-phase-graph/EvsePhaseChart';

export type DataKey = 'l1' | 'l2' | 'l3' | 'soc' | 'chargingBudget' | 'power';
export type VisibilityState = Record<DataKey, boolean>;

interface IChargerCurrentDelivered {
  chargerId: string;
}

export const ChargerCurrentDelivered = ({
  chargerId
}: IChargerCurrentDelivered) => {
  const [evseCount, setEvseCount] = useState(1);
  const { range } = useInsightsContext();

  const { data, isLoading } = useChargerEvseAggregatedTelemetry(
    chargerId,
    range[0].toISOString(),
    range[1].toISOString()
  );

  // Update EVSE count when data changes
  useEffect(() => {
    if (data && data.length > 0) {
      setEvseCount(data.length);
    }
  }, [data]);

  if (isLoading) {
    return Array.from({ length: evseCount }, (_, index) => (
      <Evse
        key={index + 1}
        chargerId={chargerId}
        evseId={(index + 1).toString()}
        data={[]}
        isLoading={true}
        isLast={evseCount === index + 1}
      />
    ));
  }

  return data?.map((evse, index) => (
    <Evse
      key={evse.id}
      chargerId={chargerId}
      evseId={evse.id}
      data={evse.telemetry}
      isLoading={false}
      isLast={data.length === index + 1}
    />
  ));
};

interface IEvse {
  chargerId: string;
  evseId: string;
  data: ChargingTelemetryPoint[];
  isLoading: boolean;
  isLast: boolean;
}

const Evse = ({ chargerId, evseId, data, isLoading, isLast }: IEvse) => {
  const { activeUnit } = useInsightsContext();
  const [visibleLines, setVisibleLines] = useState<VisibilityState>({
    l1: true,
    l2: true,
    l3: true,
    soc: true,
    power: true,
    chargingBudget: true
  });
  const { t } = usePortalTranslation('chargers');

  type TelemetryWithNumericTime = Omit<ChargingTelemetryPoint, 'timestamp'> & {
    timestamp: string;
    numericTimestamp: number;
  };

  const mapped = useMemo(() => {
    return data.map(d => {
      if (typeof d.timestamp === 'string') {
        const result: TelemetryWithNumericTime = {
          ...d,
          timestamp: d.timestamp,
          numericTimestamp: getTime(new Date(d.timestamp))
        };
        return result;
      }
      return d;
    });
  }, [data]);

  const title =
    activeUnit === UNIT_AMPERE
      ? t('literals.current_delivered')
      : t('literals.energy_delivered');

  return (
    <>
      <div className="mb-3 flex-initial">
        <Tooltip
          message={
            <EvseStatusAxisTooltipContent
              chargerId={chargerId}
              evseId={evseId}
            />
          }
        >
          <Typography variant="smallTitle">{`EVSE ${evseId} - ${title}`}</Typography>
        </Tooltip>
      </div>
      <div>
        <EvsePhaseChart
          data={mapped}
          isLoading={isLoading}
          height="239px"
          visibleLines={visibleLines}
        />
      </div>
      <div className={`flex-initial ${isLast ? '' : 'mb-8'}`}>
        <ChargerCurrentDeliveredLegend
          visibleLines={visibleLines}
          setVisibleLines={setVisibleLines}
          activeMetric={activeUnit}
        />
      </div>
    </>
  );
};
