import { useCallback, useMemo, useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { ProcessedTelemetryPoint } from '@hooks/use-site-insights-data';

import { CurrentDeliveredGraph } from '@components/sites/site/insights/site-level/current-delivered-graph/CurrentDeliveredGraph';

import { Card, SegmentControl, Typography } from '@destination/components';

import { PHASE_1, PHASE_2, PHASE_3 } from './site-insights-tooltip-utils';

interface ICurrentInsights {
  data: ProcessedTelemetryPoint[];
  isLoading: boolean;
}

export const CurrentInsights = ({ data, isLoading }: ICurrentInsights) => {
  const { t, t_generic } = usePortalTranslation('chargers');
  const [activePhase, setActivePhase] = useState(PHASE_1);

  const phaseToggleOptions: [
    { label: string; value: string | number },
    { label: string; value: string | number },
    { label: string; value: string | number }
  ] = useMemo(
    () => [
      {
        label: t_generic('literals.phase 1', { titleize: true }),
        value: PHASE_1
      },
      {
        label: t_generic('literals.phase 2', { titleize: true }),
        value: PHASE_2
      },
      {
        label: t_generic('literals.phase 3', { titleize: true }),
        value: PHASE_3
      }
    ],
    [t_generic]
  );

  const handlePhaseChange = useCallback((phase: string | number) => {
    setActivePhase(phase.toString());
  }, []);

  return (
    <div>
      <Typography className="mb-4" variant="smallTitle">
        {t('literals.current_delivered', { titleize: true })}
      </Typography>
      <SegmentControl
        options={phaseToggleOptions}
        selected={activePhase}
        onChange={handlePhaseChange}
      />
      <Card className="mt-4">
        <CurrentDeliveredGraph
          data={data}
          isLoading={isLoading}
          activePhase={activePhase}
        />
      </Card>
    </div>
  );
};
