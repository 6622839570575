/* eslint-disable react-compiler/react-compiler */

import { useEffect, useMemo, useRef } from 'react';

import useSWR from 'swr';

import apiClient from '@api/apiClient';
import { SITE_AGGREGATED_TELEMETRY_PATH } from '@api/paths';
import { filterDataByDateRange, isRangeWithinRange } from '@utils/date-utils';

import { SiteTelemetryPoint } from '@omnis-pulse-types';

interface Cache {
  data: SiteTelemetryPoint[] | null;
  from: string | null;
  to: string | null;
  timestamp: number | null; //for cache invalidation
}
const CACHE_EXPIRATION_MS = 5 * 60 * 1000;

const createCachingFetcher = () => {
  const cache: Cache = {
    data: null,
    from: null,
    to: null,
    timestamp: null
  };

  const invalidateCache = () => {
    cache.data = null;
    cache.from = null;
    cache.to = null;
    cache.timestamp = null;
  };

  const isCacheValid = (): boolean => {
    return (
      cache.timestamp !== null &&
      Date.now() - cache.timestamp < CACHE_EXPIRATION_MS &&
      cache.data !== null
    );
  };

  return {
    fetch: async (
      url: string,
      from: string,
      to: string
    ): Promise<SiteTelemetryPoint[]> => {
      // Check if cache is expired
      if (!isCacheValid()) {
        // Fetch new data if cache is expired
        const { data: newData } = await apiClient.get(url);

        // Update cache with new data and timestamp
        cache.data = newData;
        cache.from = from;
        cache.to = to;
        cache.timestamp = Date.now();

        return newData;
      }

      // If cache is valid, check if we can use filtered cached data
      if (
        cache.data &&
        cache.from &&
        cache.to &&
        isRangeWithinRange(
          { start: from, end: to },
          { start: cache.from, end: cache.to }
        )
      ) {
        // Return filtered data from cache
        return filterDataByDateRange(cache.data, from, to);
      }

      // If range doesn't match, fetch new data
      const { data: newData } = await apiClient.get(url);

      // Update cache
      cache.data = newData;
      cache.from = from;
      cache.to = to;
      cache.timestamp = Date.now();

      return newData;
    },
    invalidate: invalidateCache
  };
};

// Create a singleton fetcher instance
const cachingService = createCachingFetcher();

interface UseSiteAggregatedTelemetryResult {
  data: SiteTelemetryPoint[] | undefined;
  isLoading: boolean;
  isError: boolean;
  mutate: () => Promise<SiteTelemetryPoint[] | undefined>;
  invalidateCache: () => void;
}

const useSiteAggregatedTelemetry = (
  siteId: string,
  from: string,
  to: string
): UseSiteAggregatedTelemetryResult => {
  // Use ref to preserve fetched data between renders
  const dataRef = useRef<SiteTelemetryPoint[]>();
  const fromToRef = useRef<{ from: string; to: string }>({ from, to });

  // When zoom happens, we want to fetch new data with the appropriate resolution
  const hasTimeRangeChanged =
    fromToRef.current.from !== from || fromToRef.current.to !== to;

  if (hasTimeRangeChanged) {
    fromToRef.current = { from, to };
  }

  const url = useMemo(
    () => `${SITE_AGGREGATED_TELEMETRY_PATH(siteId)}?from=${from}&to=${to}`,
    [siteId, from, to]
  );

  // If the time range has changed significantly due to zoom, force cache invalidation
  useEffect(() => {
    if (hasTimeRangeChanged) {
      cachingService.invalidate();
    }
  }, [hasTimeRangeChanged, from, to]);

  const { data, error, isLoading, mutate } = useSWR<SiteTelemetryPoint[]>(
    [url, from, to],
    ([fetchUrl, fetchFrom, fetchTo]: [string, string, string]) =>
      cachingService.fetch(fetchUrl, fetchFrom, fetchTo),
    {
      revalidateOnFocus: false,
      dedupingInterval: 5000 // Reduce duplicate requests during rapid zoom changes
    }
  );

  if (data) {
    dataRef.current = data;
  }

  useEffect(() => {
    return () => {
      // Invalidate cache when component unmounts or siteId changes
      cachingService.invalidate();
    };
  }, [siteId]);

  return {
    data: dataRef.current || data,
    isLoading,
    isError: Boolean(error),
    mutate,
    invalidateCache: cachingService.invalidate
  };
};

export default useSiteAggregatedTelemetry;
