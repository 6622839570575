import { useLayoutEffect, useMemo, useState } from 'react';

import { usePortalTranslation } from '@hooks/use-portal-translation';
import { getTime, subHours } from 'date-fns';
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { CustomCursor } from '@components/charts/CustomCursor';
import { CustomYAxisTick } from '@components/charts/CustomYAxisTick';
import {
  InsightsProvider,
  UNIT_KILOWATTS,
  useInsightsContext
} from '@context/InsightsContext';
import ChartIcon from '@icons/chart_96.svg';
import { formatDate, timeFormat } from '@utils/date-utils';
import { getTicksForInterval } from '@utils/insights-utils';

import { LoadingSkeleton, Typography } from '@destination/components';

import { ActiveLoadManagementMode } from '@omnis-pulse-types';

import { CurrentOrEnergyDeliveredLegend } from '../insights/site-level/CurrentOrEnergyDeliveredLegend';
import { AggregatedData } from '../insights/site-level/energy-delivered-graph/EnergyDeliveredGraph';
import { EnergyDeliveredGraphTooltip } from '../insights/site-level/energy-delivered-graph/EnergyDeliveredGraphTooltip';
import { COLORS } from '../insights/site-level/site-insights-colors';

const toDate = new Date();
const fromDate = subHours(toDate, 24);
const domain: [number, number] = [getTime(fromDate), getTime(toDate)];

type DataKey =
  | 'chargers_power'
  | 'residual_power'
  | 'grid_limit_power'
  | 'load_shedding_power'
  | 'charger_budget_power_total'
  | 'peak_capacity_limit';

export type VisibilityState = Record<DataKey, boolean>;

export const SiteOverviewEnergyChart = () => {
  return (
    <InsightsProvider
      initialUnit={UNIT_KILOWATTS}
      initialTimeRange={[fromDate, toDate]}
    >
      <ChartWithData />
    </InsightsProvider>
  );
};

const ChartWithData = () => {
  const { t } = usePortalTranslation('sites');
  const { aggregatedTelemetry, isLoading, isLoading_all } =
    useInsightsContext();

  const processedData = useMemo(() => {
    if (!aggregatedTelemetry || aggregatedTelemetry.length === 0) return [];

    return aggregatedTelemetry.map(item => {
      // Sum the active power across all three phases for both chargers and residual
      const chargers_power_raw =
        (item.chargers_power_l1 ?? 0) +
        (item.chargers_power_l2 ?? 0) +
        (item.chargers_power_l3 ?? 0);

      const residual_power_raw =
        (item.residual_power_l1 ?? 0) +
        (item.residual_power_l2 ?? 0) +
        (item.residual_power_l3 ?? 0);

      return {
        ...item,
        chargers_power_raw,
        residual_power_raw,
        grid_limit_power_raw: item.grid_limit_power,

        chargers_power: chargers_power_raw / 1000,
        residual_power: residual_power_raw / 1000,
        grid_limit_power: item.grid_limit_power
          ? item.grid_limit_power / 1000
          : undefined,
        load_shedding_power: item.load_shedding_power
          ? item.load_shedding_power / 1000
          : undefined,
        charger_budget_power_total: item.charger_budget_power
          ? item.charger_budget_power / 1000
          : undefined,
        peak_capacity_limit: undefined
      };
    });
  }, [aggregatedTelemetry]);

  if (isLoading_all) {
    return (
      <LoadingSkeleton isDarker className="!h-full rounded-[10px]" height={0} />
    );
  }

  if (processedData.length === 0) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <ChartIcon />
        <Typography variant="smallText" className="w-48 pt-6 text-center">
          {t('messages.no_grid_connection_measurements')}
        </Typography>
      </div>
    );
  }

  return <Chart data={processedData} isLoading={isLoading} />;
};

interface IChart {
  data: AggregatedData;
  isLoading: boolean;
}

const Chart = ({ data, isLoading }: IChart) => {
  const { t } = usePortalTranslation();
  const {
    activeLoadManagementMode,
    isPeakCapacityEnabled,
    peakCapacityLimit,
    isLocalModbusEnabled
  } = useInsightsContext();

  const isStaticMode =
    activeLoadManagementMode === ActiveLoadManagementMode.STATIC;

  const [visibleLines, setVisibleLines] = useState<VisibilityState>({
    chargers_power: true,
    residual_power: true,
    grid_limit_power: true,
    load_shedding_power: isLocalModbusEnabled,
    charger_budget_power_total: isStaticMode,
    peak_capacity_limit: isPeakCapacityEnabled
  });

  useLayoutEffect(() => {
    setVisibleLines(prevState => ({
      ...prevState,
      charger_budget_power_total: isStaticMode,
      peak_capacity_limit: isPeakCapacityEnabled,
      load_shedding_power: isLocalModbusEnabled
    }));
  }, [isStaticMode, isPeakCapacityEnabled, isLocalModbusEnabled]);

  const axis = useMemo(() => {
    return getAxis(domain);
  }, []);

  const enhancedData = useMemo(() => {
    if (!isPeakCapacityEnabled || peakCapacityLimit === null) {
      return data;
    }

    return data.map(item => ({
      ...item,
      peak_capacity_limit: peakCapacityLimit / 1000
    }));
  }, [data, isPeakCapacityEnabled, peakCapacityLimit]);

  return (
    <div
      className="flex h-full flex-col"
      data-testid="site-overview-energy-chart"
    >
      <div className="relative flex-auto">
        {isLoading && (
          <div className="absolute inset-0 z-50 mb-[33px] ml-[45px] mr-1 mt-1 bg-white">
            <LoadingSkeleton className="!h-full rounded-[10px]" height={0} />
          </div>
        )}
        <ResponsiveContainer height="100%" width="100%">
          <ComposedChart data={enhancedData}>
            <CartesianGrid strokeDasharray="5 5" stroke="#EBEBEB" />
            {axis}
            <Tooltip
              allowEscapeViewBox={{ x: true, y: true }}
              offset={0}
              isAnimationActive={false}
              shared={true}
              content={<EnergyDeliveredGraphTooltip t={t} />}
              cursor={enhancedData.length ? <CustomCursor /> : <></>}
              wrapperStyle={{ zIndex: 100, pointerEvents: 'none' }}
            />
            <Area
              data={enhancedData}
              dataKey="residual_power"
              name={`${t('labels.entity_load', { entity: t('literals.residual'), titleize: true })}`}
              fill={COLORS.RESIDUAL}
              stroke={COLORS.RESIDUAL}
              fillOpacity={0.6}
              unit="kW"
              type="monotone"
              isAnimationActive={false}
              stackId="1"
              connectNulls={true}
            />
            <Area
              data={enhancedData}
              dataKey="chargers_power"
              name={`${t('labels.entity_consumption', { titleize: true, entity: t('literals.charger_other') })}`}
              fill={COLORS.CHARGERS}
              stroke={COLORS.CHARGERS}
              fillOpacity={0.6}
              unit="kW"
              type="monotone"
              isAnimationActive={false}
              stackId="1"
              connectNulls={true}
            />
            {/* Limit lines */}
            {visibleLines.grid_limit_power && (
              <Line
                data={enhancedData}
                dataKey="grid_limit_power"
                name={`${t('literals.grid_limit', { titleize: true })}`}
                stroke={COLORS.GRID_LIMIT}
                strokeWidth={2}
                dot={false}
                unit="kW"
                type="monotone"
                isAnimationActive={false}
                connectNulls={true}
              />
            )}
            {visibleLines.load_shedding_power && (
              <Line
                data={enhancedData}
                dataKey="load_shedding_power"
                name={`${t('labels.entity_limit', { entity: t('literals.modbus'), titleize: true })}`}
                stroke={COLORS.LOAD_SHEDDING}
                strokeWidth={2}
                dot={false}
                unit="kW"
                type="monotone"
                isAnimationActive={false}
                connectNulls={true}
              />
            )}
            {visibleLines.charger_budget_power_total && (
              <Line
                data={enhancedData}
                dataKey="charger_budget_power_total"
                name={`${t('labels.entity_limit', { entity: t('literals.charging_budget'), titleize: true })}`}
                stroke={COLORS.CHARGING_BUDGET}
                strokeWidth={2}
                dot={false}
                unit="kW"
                type="monotone"
                isAnimationActive={false}
                connectNulls={true}
              />
            )}
            {visibleLines.peak_capacity_limit && (
              <Line
                data={enhancedData}
                dataKey="peak_capacity_limit"
                name={`${t('labels.entity_limit', { entity: t('literals.peak_capacity'), titleize: true })}`}
                stroke={COLORS.PEAK_CAPACITY}
                strokeWidth={2}
                strokeDasharray="4 4"
                dot={false}
                unit="kW"
                type="monotone"
                isAnimationActive={false}
                connectNulls={true}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className="flex-initial">
        <CurrentOrEnergyDeliveredLegend />
      </div>
    </div>
  );
};

const getAxis = (domain: [number, number]) => [
  <XAxis
    key={1}
    dataKey="timestamp"
    type="number"
    scale="time"
    domain={domain}
    stroke="#EBEBEB"
    tickFormatter={tick => formatDate(tick, timeFormat)}
    tick={{ fontSize: 12, fill: '#9F9F9F', fontFamily: 'ABBvoice' }}
    ticks={getTicksForInterval(domain)}
    tickLine={false}
  />,
  <YAxis
    key={2}
    stroke="#EBEBEB"
    padding={{ top: 24 }}
    tickFormatter={value => value}
    tick={<CustomYAxisTick />}
    tickLine={false}
    width={55}
    label={{
      value: 'kW',
      position: 'top',
      offset: -10,
      style: {
        fontSize: 12,
        textAnchor: 'middle',
        fill: '#9F9F9F',
        fontFamily: 'ABBvoice'
      }
    }}
  />
];
