import { usePortalTranslation } from '@hooks/use-portal-translation';

import { LegendItem } from '@components/charts/LegendItem';

export const EvseStatusChartLegend = () => {
  const { t } = usePortalTranslation();

  return (
    <div className="ml-[94px] flex space-x-6">
      <LegendItem
        color="#99D794"
        label={t('states.available', { titleize: true })}
      />
      <LegendItem
        color="#9FBEF7"
        label={t('states.charging', { titleize: true })}
      />
      <LegendItem
        label={t('labels.entity_event', {
          entity: t('states.other', { titleize: true })
        })}
        style={{
          backgroundImage:
            'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNSIgdmlld0JveD0iMCAwIDEwIDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGlkPSJGcmFtZSA3NTI3MCI+CjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8yMTg4OV8xMDc2MjMpIj4KPHJlY3QgaWQ9IlJlY3RhbmdsZSAyNDk2IiB3aWR0aD0iMTAiIGhlaWdodD0iNSIgcng9IjEiIGZpbGw9IiNGRkQzQTgiLz4KPHBhdGggaWQ9IlZlY3RvciA4NjQiIGQ9Ik0xMS41IC00TC0zIDEwLjUiIHN0cm9rZT0iI0ZGQTIwMCIvPgo8cGF0aCBpZD0iVmVjdG9yIDg2NiIgZD0iTTE0LjUgLTRMMCAxMC41IiBzdHJva2U9IiNGRkEyMDAiLz4KPHBhdGggaWQ9IlZlY3RvciA4NjgiIGQ9Ik0xNy41IC00TDMgMTAuNSIgc3Ryb2tlPSIjRkZBMjAwIi8+CjxwYXRoIGlkPSJWZWN0b3IgODY1IiBkPSJNOC41IC00TC02IDEwLjUiIHN0cm9rZT0iI0ZGQTIwMCIvPgo8cGF0aCBpZD0iVmVjdG9yIDg2NyIgZD0iTTUuNSAtNEwtOSAxMC41IiBzdHJva2U9IiNGRkEyMDAiLz4KPC9nPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzIxODg5XzEwNzYyMyI+CjxyZWN0IHdpZHRoPSIxMCIgaGVpZ2h0PSI1IiByeD0iMSIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K")',
          backgroundSize: 'cover'
        }}
      />
      <LegendItem
        color="bg-[#99D794]"
        label={t('states.error', { titleize: true })}
        style={{
          backgroundImage:
            "url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNSIgdmlld0JveD0iMCAwIDEwIDUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGlkPSJGcmFtZSA3NTI3MSI+CjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8yMTg4OV8xMDc2MzQpIj4KPHJlY3QgaWQ9IlJlY3RhbmdsZSAyNDk2IiB3aWR0aD0iMTAiIGhlaWdodD0iNSIgcng9IjEiIGZpbGw9IiNGRkM5Q0QiLz4KPGcgaWQ9Ikdyb3VwIDc0NjI0Ij4KPHBhdGggaWQ9IlZlY3RvciA4NjAiIGQ9Ik0xIDEuNUwzLjAwMDA4IDMuNTAwMDgiIHN0cm9rZT0iI0VGMzkzNCIvPgo8cGF0aCBpZD0iVmVjdG9yIDg2MSIgZD0iTTMgMS41TDAuOTk5OTE4IDMuNTAwMDgiIHN0cm9rZT0iI0VGMzkzNCIvPgo8L2c+CjxnIGlkPSJHcm91cCA3NDYyNSI+CjxwYXRoIGlkPSJWZWN0b3IgODYwXzIiIGQ9Ik03IDEuNUw5LjAwMDA4IDMuNTAwMDgiIHN0cm9rZT0iI0VGMzkzNCIvPgo8cGF0aCBpZD0iVmVjdG9yIDg2MV8yIiBkPSJNOSAxLjVMNi45OTk5MiAzLjUwMDA4IiBzdHJva2U9IiNFRjM5MzQiLz4KPC9nPgo8L2c+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMjE4ODlfMTA3NjM0Ij4KPHJlY3Qgd2lkdGg9IjEwIiBoZWlnaHQ9IjUiIHJ4PSIxIiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=')",
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      />
    </div>
  );
};
