import { usePortalTranslation } from '@hooks/use-portal-translation';
import { useParams } from 'react-router-dom';

import updateSiteLoadManagementFallbackBudget from '@api/sites/update-site-load-management-fallback-budget';
import useSiteLoadManagementFallbackBudget from '@api/sites/use-site-load-management-fallback-budget';
import { useReadOnly } from '@context/ReadOnlyContext';
import { useSiteDevicesContext } from '@context/SiteDevicesContext';
import { isValidPositiveNumber } from '@utils/input-utils';

import {
  AutomaticSaveInput,
  Card,
  DataError,
  Disclaimer,
  LoadingSkeleton,
  Typography
} from '@destination/components';

import { UpdateSiteFallbackBudget } from '@omnis-pulse-types';

export const FallbackBudget = () => {
  const { t, t_generic } = usePortalTranslation('siteSettings');
  const { t: t_errors } = usePortalTranslation('errors');
  const { id } = useParams();
  const { data, isLoading, isError, mutate } =
    useSiteLoadManagementFallbackBudget(id);

  const { deviceData } = useSiteDevicesContext();
  const { isReadOnly } = useReadOnly();

  const label = t('fallback_budget.label', { titleize: true });
  const invalidHelperText = t_errors('invalid_input', {
    titleize: true
  });
  const errorHelperText = t_errors('failed_to_save', {
    entity: t_generic('literals.capacity')
  });

  const helperText = t('fallback_budget.helper_text', {
    titleize: true,
    value: deviceData.chargers?.length
  });

  const handleSave = async (value: string) => {
    const payload: UpdateSiteFallbackBudget = {
      evseFallbackBudget: parseFloat(value)
    };

    if (id) {
      const { error } = await updateSiteLoadManagementFallbackBudget(
        id,
        payload
      );
      if (error) throw new Error();
      await mutate();
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return <LoadingSkeleton height={20} />;
    }

    if (isError) {
      return <DataError onRefresh={() => mutate()} />;
    }

    if (data === undefined) {
      return null;
    }

    return (
      <div className="flex flex-col gap-4 px-3 py-4">
        <Disclaimer
          variant="warning"
          message={t('fallback_budget.warning_message')}
        />
        <div className="flex items-center gap-4">
          <div className="min-w-[200px]">
            <Typography>{label}</Typography>
          </div>
          <div className="w-[200px]">
            <AutomaticSaveInput
              data-testid="fallback-budget-input"
              disabled={isReadOnly}
              initialValue={data.evseFallbackBudget.toString()}
              id="fallback-budget-value"
              validator={isValidPositiveNumber}
              invalidHelperText={invalidHelperText}
              errorHelperText={errorHelperText}
              onSave={handleSave}
              suffixText={<Typography variant="smallTitle">A</Typography>}
              helperText={helperText}
            />
          </div>
        </div>
      </div>
    );
  };

  return <Card className="w-[620px]">{renderContent()}</Card>;
};
