import { useParams } from 'react-router-dom';

import updateSiteChargingStrategy from '@api/sites/update-site-charging-strategy';
import useSiteChargingStrategy from '@api/sites/use-site-charging-strategy';
import { useReadOnly } from '@context/ReadOnlyContext';

import { Card, DataError, LoadingSkeleton } from '@destination/components';

import { UpdateSiteChargingStrategy } from '@omnis-pulse-types';

import { ChargingStrategyBasePower } from './ChargingStrategyBasePower';
import { ChargingStrategySelector } from './ChargingStrategySelector';

export const ChargingStrategy = () => {
  const { id } = useParams();

  const { data, isLoading, isError, mutate } = useSiteChargingStrategy(id);

  const { isReadOnly } = useReadOnly();

  const handleSave = async (payload: UpdateSiteChargingStrategy) => {
    if (id) {
      const { error } = await updateSiteChargingStrategy(id, payload);
      if (error) throw new Error();
      await mutate();
    }
  };

  if (isError) {
    return (
      <Card className="w-[620px]">
        <DataError onRefresh={() => mutate(undefined, true)} />
      </Card>
    );
  }
  if (isLoading) {
    return (
      <Card className="w-[620px]">
        <LoadingSkeleton height={20} />
      </Card>
    );
  }

  if (data === undefined) {
    return null;
  }

  return (
    <div className="flex flex-col gap-1">
      <Card className="w-[620px]">
        <ChargingStrategySelector
          isDisabled={isReadOnly}
          strategy={data.strategy}
          onSave={handleSave}
        />
      </Card>
      <Card className="w-[620px]">
        <ChargingStrategyBasePower
          isDisabled={isReadOnly}
          basePower={data.basePower}
          onSave={handleSave}
        />
      </Card>
    </div>
  );
};
